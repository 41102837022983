import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import React from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { Button, Icon, useTheme } from "react-native-elements";

import { Routes } from "../../constants";

interface PostOnboardingScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const PostOnboardingScreen: React.FC<PostOnboardingScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const primaryColor = theme.colors?.primary;

  const handleProceed = (): void => {
    navigation.navigate(Routes.BottomNavigationTab, { screen: Routes.DiaryViewScreen });
  };

  return (
    <ScrollView contentContainerStyle={styles.container} testID="scrollView">
      <View style={styles.header} testID="header">
        <Icon
          name="arrow-back"
          size={24}
          onPress={() => navigation.goBack()}
          tvParallaxProperties={undefined}
          testID="back-button"
        />
      </View>
      <Icon name="robot" type="material-community" size={64} color={primaryColor} tvParallaxProperties={undefined} />
      <Text style={styles.title}>🎉 {t("onboarding.onboarding_11_post_onboarding_screen.title")} 🎉</Text>
      <Text style={styles.subtitle}>{t("onboarding.onboarding_11_post_onboarding_screen.subtitle")}</Text>
      <Text style={styles.paragraph}>{t("onboarding.onboarding_11_post_onboarding_screen.paragraph1")}</Text>
      <View style={styles.list}>
        <Text style={styles.listItem}>🍽️ {t("onboarding.onboarding_11_post_onboarding_screen.weekly_menus")}</Text>
        <Text style={styles.listItem}>🛒 {t("onboarding.onboarding_11_post_onboarding_screen.shopping_list")}</Text>
      </View>

      <Text style={styles.paragraph}>{t("onboarding.onboarding_11_post_onboarding_screen.paragraph4")}</Text>
      <Button
        title={t("onboarding.onboarding_11_post_onboarding_screen.proceed_button")}
        buttonStyle={[styles.proceedButton, { backgroundColor: primaryColor }]}
        onPress={handleProceed}
        testID="proceed-button"
      />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#f9f9f9",
    padding: 20,
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    width: "100%",
  },
  title: {
    fontSize: 26,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 16,
    color: "#333",
  },
  subtitle: {
    fontSize: 18,
    color: "#777",
    textAlign: "center",
    marginVertical: 16,
  },
  paragraph: {
    fontSize: 16,
    color: "#333",
    textAlign: "center",
    marginVertical: 8,
  },
  list: {
    marginVertical: 16,
  },
  listItem: {
    fontSize: 16,
    color: "#333",
    textAlign: "center",
    marginVertical: 4,
  },
  proceedButton: {
    paddingVertical: 14,
    paddingHorizontal: 32,
    marginTop: 20,
    borderRadius: 8,
    width: "80%",
    alignSelf: "center",
    elevation: 2,
  },
});

export default PostOnboardingScreen;
