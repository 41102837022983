import { useTheme } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Image } from "react-native-elements";

import ProgressBar from "../commons/ProgressBar";
import { Images } from "../constants";

interface PostOnboardingLoadingInterstitialProps {
  size?: "sm" | "lg";
}

const PostOnboardingLoadingInterstitial: React.FC<PostOnboardingLoadingInterstitialProps> = ({ size = "lg" }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [messageIndex, setMessageIndex] = useState(0);

  const messages = t("loading_component.messages", { returnObjects: true });

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 12; // 100% / (25 seconds / 3 seconds) ≈ 12
      });

      setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 3000); // Update every 3 seconds

    return () => clearInterval(interval);
  }, [messages]);

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.content}>
        <Image source={Images.DefaultSpinner} width={size === "lg" ? 100 : 50} height={size === "lg" ? 100 : 50} />
        <Text style={styles.title}>{t("loading_component.title")}</Text>
        <Text style={styles.subtitle}>{t("loading_component.subtitle")}</Text>
        <Text style={styles.message}>{messages[messageIndex]}</Text>
        <View style={styles.progressBarContainer}>
          <ProgressBar value={progress} width={300} />
        </View>
        <Text style={styles.additionalInfo}>{t("loading_component.additional_info")}</Text>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  content: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "#777",
    textAlign: "center",
    marginVertical: 16,
  },
  message: {
    fontSize: 18,
    color: "#333",
    textAlign: "center",
    marginBottom: 16,
    fontStyle: "italic",
  },
  additionalInfo: {
    fontSize: 14,
    color: "#555",
    textAlign: "center",
    marginTop: 16,
  },
  progressBarContainer: {
    width: "100%",
    alignItems: "center",
    marginTop: 20,
  },
});

export default PostOnboardingLoadingInterstitial;
