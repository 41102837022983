import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Action,
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  ThunkAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import { FLUSH, PAUSE, PERSIST, PersistConfig, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist";

import { PRODUCTION } from "./app/src/constants";
import { api } from "./app/src/services/baseApi";
import logger from "./app/src/services/logger";
import { brandSlice, BrandState } from "./app/src/slices/brandSlice";
import { foodSlice, FoodState } from "./app/src/slices/foodSlice";
import { onboardingSlice, OnboardingState } from "./app/src/slices/onboardingSlice";
import { plannerSlice, PlannerState } from "./app/src/slices/plannerSlice";
import { userSlice, UserState } from "./app/src/slices/userSlice";

const DEFAULT_USER_FACING_GENERIC_ERROR_MESSAGE = "Oops, something went wrong. Please try again.";

logger.debug("process.env.ENVIRONMENT:", process.env.ENVIRONMENT);

const RUNNING_IN_CYPRESS = process.env.ENVIRONMENT === "cypress";

if (RUNNING_IN_CYPRESS) {
  console.log("Running in Cypress.");
}

/**
 * We keep this to avoid bombarding the user with multiple error messages in quick succession.
 */
let timeOfLastAlertToUser = 0;

const MIN_PERIOD_BETWEEN_GENERIC_ERRORS_TO_USER = 1000;

/**
 * NOTE: This is brittle and will break if the name of the endpoint changes
 */
const GET_MY_USER_INFO_QUERYCACHEKEY = "usersAuthUsersMeRetrieve(undefined)";

export const rtkQueryErrorLogger: Middleware = (middlewareApi: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { type, payload, meta } = action;

    const invalidTokenResponseForGetUserInfo =
      meta &&
      meta.arg &&
      meta.arg.queryCacheKey === GET_MY_USER_INFO_QUERYCACHEKEY &&
      payload &&
      payload.status === 401;

    const errorWithCalendarDayCreation =
      meta &&
      meta.arg &&
      meta.arg.endpointName === "plannerCalendarDayCreate" &&
      meta.arg.type === "mutation" &&
      payload &&
      payload.status === 400;

    if (invalidTokenResponseForGetUserInfo) {
      // NOTE: This is handled where the hook is used so we don't need to do anything here.
      logger.warn("Invalid token - user should log in again");
    } else if (errorWithCalendarDayCreation) {
      // NOTE: This error is due to a (relatively rare) race condition and is fixed on the next poll so we ignore it
    } else {
      logger.warn("We got a rejected action!", type);
      if (RUNNING_IN_CYPRESS) {
        console.log(JSON.stringify(payload, null, 2));
      }

      logger.warn("The payload is:", payload);
      if (RUNNING_IN_CYPRESS) {
        console.log(JSON.stringify(payload, null, 2));
      }

      const errorForSentry = new Error(`${String(type)} - ${JSON.stringify(payload, null, 2)}`);
      Sentry.captureException(errorForSentry);

      let errorMessageForUser = DEFAULT_USER_FACING_GENERIC_ERROR_MESSAGE;

      if (payload.status === 400) {
        const { non_field_errors: nonFieldErrors } = payload.data;
        if (nonFieldErrors) {
          [errorMessageForUser] = nonFieldErrors;
        }

        const userHasRecentlyReceivedAGenericErrorMessage =
          timeOfLastAlertToUser + MIN_PERIOD_BETWEEN_GENERIC_ERRORS_TO_USER < Date.now();
        if (userHasRecentlyReceivedAGenericErrorMessage) {
          timeOfLastAlertToUser = Date.now();
          // TODO: We should handle this more gracefully with a UI library
          alert(errorMessageForUser);
          // Toast.warn({ title: "Async error!", message: action.error.data.message });
        }
      }

      // NOTE: This is for debugging the compiled app
      // alert(`Error with action: ${type}\n${JSON.stringify(payload, null, 2)}`);
    }
  }

  return next(action);
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const combinedReducers = combineReducers({
  [api.reducerPath]: api.reducer,
  [plannerSlice.name]: plannerSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [foodSlice.name]: foodSlice.reducer,
  [brandSlice.name]: brandSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
});

export const USER_LOGOUT_ACTION = "USER_LOGOUT";

type RootReducerState = CombinedState<{
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  api: CombinedState<unknown, never, "api">;
  planner: PlannerState;
  user: UserState;
  food: FoodState;
  brand: BrandState;
  onboarding: OnboardingState;
}>;

const rootReducer = (state: RootReducerState | undefined, action: AnyAction): RootReducerState => {
  if (action.type === USER_LOGOUT_ACTION) {
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
};

const persistConfig: PersistConfig<RootReducerState, unknown, unknown, unknown> = {
  key: "root",
  version: 1,
  storage: AsyncStorage,
  // NOTE: We believe that allowing this to be persisted causes intermittent errors and thus it should be blacklisted
  blacklist: [api.reducerPath],
};

export const store = configureStore({
  devTools: !PRODUCTION,
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger),
  enhancers: [sentryReduxEnhancer],
  // TODO: Add this for development: https://github.com/leoasis/redux-immutable-state-invariant
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
