import { MaterialIcons } from "@expo/vector-icons";
import _ from "lodash";
// eslint-disable-next-line import/no-named-default
import { default as MomentLib } from "moment";
import { extendMoment } from "moment-range";
import { Button, useTheme, View } from "native-base";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Calendar, DateData } from "react-native-calendars";
import type { MarkingProps } from "react-native-calendars/src/calendar/day/marking";

import { Scale } from "../constants";
import { FontFamily } from "../constants/fonts";
import { formatMomentAsBackendFormatDateString } from "../helpers/generalHelpers";
import logger from "../services/logger";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const moment = extendMoment(MomentLib);

/**
 * This is from the react-native-calendars library but we defined it ourselves because it is not exported.
 */
export type MarkedDatesType = {
  [key: string]: MarkingProps;
};

const DEFAULT_NUM_DAY_FOR_GROCERY_LIST = 7;

const AddDateRange = (props: {
  createGroceryList: (startDate: moment.Moment, endDate: moment.Moment) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const { createGroceryList } = props;

  const initialStartDate = moment();
  // NOTE: Minus 1 because people expect it to be exclusive, not inclusive (ie, Monday - Sunday, not Monday - Monday)
  const initialEndDate = moment().add(DEFAULT_NUM_DAY_FOR_GROCERY_LIST - 1, "days");
  const [startDate, setStartDate] = useState<moment.Moment>(initialStartDate);
  const [endDate, setEndDate] = useState<moment.Moment | undefined>(initialEndDate);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const markedDateBaseProperties: MarkingProps = {
    color: colors.primary["600"],
    textColor: "white",
  };

  const initialGroceryListRange = moment.range(moment(initialStartDate), moment(initialEndDate)).by("days");
  const defaultMarkedDates: MarkedDatesType = _.fromPairs(
    Array.from(initialGroceryListRange).map((m) => [m.format("YYYY-MM-DD"), { ...markedDateBaseProperties }])
  );

  const [markedDates, setMarkedDates] = useState<MarkedDatesType>(defaultMarkedDates);

  const onDayPress = (day: DateData): void => {
    if (!startDate || (startDate && endDate)) {
      const updatedMarkedDates: MarkedDatesType = {
        [day.dateString]: { ...markedDateBaseProperties },
      };
      setMarkedDates(updatedMarkedDates);
      setEndDate(undefined);
      setStartDate(moment(day.dateString));
    } else {
      const updatedMarkedDates = _.cloneDeep(markedDates);
      const calculatedEndDate = moment(day.dateString);
      const numDaysInPeriod = calculatedEndDate.diff(startDate, "days");

      const startDateCopy = moment(startDate);
      const markDayInRange = (index: number): void => {
        // NOTE: Deliberate mutation
        const tempDate = startDateCopy.add(1, "day");

        if (index < numDaysInPeriod) {
          updatedMarkedDates[formatMomentAsBackendFormatDateString(tempDate)] = markedDateBaseProperties;
        } else {
          updatedMarkedDates[formatMomentAsBackendFormatDateString(tempDate)] = {
            ...markedDateBaseProperties,
            // NOTE: I think the styling looks better with the startingDay and endingDay rounding effect
            // endingDay: true,
          };
        }
      };

      if (numDaysInPeriod > 0) {
        _.range(1, numDaysInPeriod + 1).forEach(markDayInRange);

        setMarkedDates(updatedMarkedDates);
        setEndDate(calculatedEndDate);
      } else {
        // A date before the startDate was picked, thus reset the startDate
        setStartDate(moment(day.dateString));
        setEndDate(undefined);
        setMarkedDates({
          [day.dateString]: { ...markedDateBaseProperties },
        });
      }
    }
  };

  const shouldShowCreateButton = startDate && endDate;
  const disableArrowLeft = true;
  return (
    <View testID="groceryListDatePicker-div">
      {/* TODO: Localise this component? */}
      <Calendar
        monthFormat="MMMM"
        onDayPress={onDayPress}
        style={{ marginHorizontal: Scale(22), marginVertical: Scale(10) }}
        markingType="period"
        markedDates={markedDates}
        theme={{
          // TODO: I think these styles are superfluous but I will leave this code here for reference
          // "stylesheet.day.period": {
          //   today: {
          //     backgroundColor: Colors.currentDateBackgroundColor,
          //     borderRadius: Scale(35 / 2),
          //     width: Scale(35),
          //     height: Scale(35),
          //   },
          // },
          // backgroundColor: Colors.whiteColor,
          // calendarBackground: Colors.whiteColor,
          // textSectionTitleColor: Colors.borderColor,
          // textSectionTitleDisabledColor: "#d9e1e8",
          // selectedDayTextColor: "#ffffff",
          // todayTextColor: Colors.descriptionTextColor,
          // todayBackgroundColor: Colors.currentDateBackgroundColor,
          // todayDotColor: "red",
          // dayTextColor: Colors.smallTextColor,
          // textDisabledColor: "#d9e1e8",
          // arrowColor: Colors.textColor,
          // disabledArrowColor: Colors.borderColor,
          // monthTextColor: Colors.largeTextColor,
          // textDayStyle: { backgroundColor: "red" },
          // selectedDayBackgroundColor: "red",
          textDayFontFamily: FontFamily.medium,
          textMonthFontFamily: FontFamily.bold,
          textDayHeaderFontFamily: FontFamily.medium,
          textDayFontSize: Scale(14),
          textMonthFontSize: Scale(16),
          textDayHeaderFontSize: Scale(16),
          arrowColor: colors.primary["600"],
        }}
        renderArrow={(direction) => (
          /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
          /* @ts-ignore */
          <MaterialIcons
            name={direction === "left" ? "chevron-left" : "chevron-right"}
            size={24}
            color={direction === "left" && disableArrowLeft ? colors.gray["300"] : colors.primary["600"]}
          />
        )}
        disableArrowLeft={disableArrowLeft}
        firstDay={1}
      />
      {/* TODO: Perhaps we should use formik to validate this? */}
      {shouldShowCreateButton ? (
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={async () => {
            if (!startDate) {
              // TODO: Create helper function that will throw an error but also surface an alert to the user
              throw new Error("Start date is not set");
            }
            if (!endDate) {
              throw new Error("End date is not set");
            }
            setSubmitting(true);
            setTimeout(() => {
              setSubmitting(false);
            }, 1000);

            await createGroceryList(startDate, endDate);
            setSubmitting(false);
          }}
          testID={"createGroceryListDatePicker-button"}
          isLoading={submitting}
        >
          {t("grocery_list.create_list_button")}
        </Button>
      ) : null}
    </View>
  );
};
export default AddDateRange;

// NOTE: This is here for future reference
// const styles = StyleSheet.create({
//   header: {
//     flexDirection: "row",
//     justifyContent: "space-between",
//     borderTopWidth: Scale(1),
//     paddingVertical: Scale(20),
//     paddingLeft: Scale(14),
//     paddingRight: Scale(20),
//     borderTopColor: Colors.disableButton,
//     borderBottomColor: Colors.disableButton,
//     borderBottomWidth: Scale(1),
//   },
//   headerText: {
//     fontFamily: FontFamily.medium,
//     fontSize: Scale(16),
//   },
// });
