import { StyleSheet } from "react-native";

import { Scale, VerticalScale, width } from "../constants";

const styles = StyleSheet.create({
  imgViewContainer: {
    width,
    height: width,
    justifyContent: "center",
    alignItems: "center",
  },
  defaultImgContainer: {
    width: width * 0.7,
    height: width * 0.7,
  },
  imgContainer: {
    width,
    height: width,
  },
  imgContainerDesktop: {
    width,
    height: 400,
  },
  closeIconContaier: {
    position: "absolute",
    zIndex: 1000,
    right: Scale(16),
    top: VerticalScale(60),
  },
  closeIcon: {
    width: Scale(32),
    height: Scale(32),
  },
  editIconContaier: {
    // position: "absolute",
    // zIndex: 1000,
    // right: Scale(16),
    // top: VerticalScale(60),
  },
  editIcon: {
    width: Scale(32),
    height: Scale(32),
  },
  favouriteIconContainer: {
    position: "absolute",
    zIndex: 1000,
    right: Scale(16),
    bottom: VerticalScale(10),
  },
  prepTimeContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginTop: VerticalScale(32),
  },
  clockIcon: {
    width: Scale(15),
    height: Scale(15),
    marginRight: Scale(6),
  },
  titleText: {
    marginTop: VerticalScale(8),
    marginBottom: VerticalScale(24),
  },
  recipeMacrosContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tabViewContainer: {
    marginTop: VerticalScale(35),
  },
  ingredientsListContainer: {
    marginVertical: VerticalScale(32),
    marginBottom: VerticalScale(50),
  },
  ingredientRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: VerticalScale(8),
  },
  addIngredientBtn: {
    backgroundColor: "#FFF",
    borderRadius: 8,
    // borderColor: Colors.disableButton,
    borderWidth: 1,
    borderStyle: "solid",
    width: width - Scale(48),
  },
  stepContainer: {
    marginVertical: VerticalScale(8),
    flexDirection: "row",
    alignItems: "center",
    marginRight: Scale(15),
  },
  tipContainer: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: Colors.tipColor,
    paddingVertical: VerticalScale(13),
    paddingHorizontal: Scale(12),
  },
  tipIcon: { width: Scale(24), height: Scale(24), marginRight: Scale(12) },
  noteOnRecipe: {
    paddingHorizontal: Scale(15),
    paddingVertical: Scale(12),
    borderWidth: 1,
    // borderColor: Colors.disableButton,
    marginTop: VerticalScale(30),
  },
});

export default styles;
