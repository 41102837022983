import { Entypo, MaterialIcons } from "@expo/vector-icons";
import {
  Box,
  Button,
  Center,
  Heading,
  Image,
  KeyboardAvoidingView,
  PresenceTransition,
  Row,
  Stack,
  Text,
  View as NBView,
} from "native-base";
import platform from "platform-detect";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ScreenWidth } from "react-native-elements/dist/helpers";
import { useDispatch } from "react-redux";

import { Images, Scale } from "../constants";
import { getAppName } from "../helpers/generalHelpers";
import { AppImage, TroubleInstallingPWAArticleComponent } from "../helpers/supportHelpers";
import { userSlice } from "../slices/userSlice";
import styles from "./LoginScreenStyles";

// TODO: Make use of automatic install prompt (not available on iOS): beforeinstallprompt
// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeinstallprompt_event

// TODO: Use Launch Handler API (Chrome only) to add a magic link token to obviate the need for sign in
// https://developer.chrome.com/docs/web-platform/launch-handler/

// TODO: On Safari mobile, make a tooltip to show the user how to add the app to the home screen
// https://stackoverflow.com/a/61974926

const appName = getAppName();

const InstallInstructionsBox = (): JSX.Element => {
  const { t } = useTranslation();

  const isiOS = platform.ios;

  return (
    <Box alignItems="center">
      <Box
        maxW="80"
        rounded="lg"
        overflow="hidden"
        borderColor="coolGray.200"
        borderWidth="1"
        _dark={{
          borderColor: "coolGray.600",
          backgroundColor: "gray.700",
        }}
        _web={{
          shadow: 2,
          borderWidth: 0,
        }}
        _light={{
          backgroundColor: "gray.50",
        }}
      >
        <Stack p="4" space={3}>
          <Stack space={2}>
            <Heading size="md" ml="-1" alignSelf="center">
              {t("pwa_install_screen.box.heading", { appName })}
            </Heading>
          </Stack>

          <NBView mt="4">
            <Text textAlign="center" fontSize="lg">
              {t("pwa_install_screen.box.subheading")}
            </Text>
            <Row mt="8" alignSelf="center">
              <Text fontSize="md">{t("pwa_install_screen.box.install_instructions_part_1")} </Text>

              {isiOS ? (
                <MaterialIcons name="ios-share" size={24} color="black" />
              ) : (
                <Entypo name="dots-three-vertical" size={24} color="black" />
              )}
              <Text fontSize="md">
                {" "}
                {t(`pwa_install_screen.box.install_instructions_part_2_${isiOS ? "ios" : "android"}`)}
              </Text>
            </Row>
          </NBView>
        </Stack>
      </Box>
    </Box>
  );
};

const PWASignupScreen = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const imageSize = Scale(ScreenWidth * 0.8 * 0.5);

  function onSubmitDeferPwaInstallPrompt(): void {
    dispatch(userSlice.actions.deferPwaInstallPrompt());
  }

  return (
    <View style={styles.wrapper}>
      <View style={{ ...styles.loginContainer, marginTop: -200 }}></View>
      <View style={styles.container}>
        <KeyboardAvoidingView behavior="position">
          <Center>
            <AppImage />

            <Box bgColor={"green"}>
              <InstallInstructionsBox />
            </Box>

            <NBView marginTop="10">
              <TroubleInstallingPWAArticleComponent />
            </NBView>

            <PresenceTransition
              visible={true}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 250,
                  delay: 5000,
                },
              }}
            >
              <Button onPress={onSubmitDeferPwaInstallPrompt} mt="16" size="sm" variant="outline">
                <Text>{t("pwa_install_screen.defer_installation_button_text")}</Text>
              </Button>
            </PresenceTransition>
          </Center>
        </KeyboardAvoidingView>
      </View>
    </View>
  );
};

export default PWASignupScreen;
