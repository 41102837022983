import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import { Box, Button, Center, FormControl, Image, Input, KeyboardAvoidingView, Text, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import type { Routes } from "../constants";
import images from "../constants/images";
import { TroubleLoggingInLink } from "../helpers/supportHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import { userSlice } from "../slices/userSlice";
import styles from "./MagicLinkSentScreenStyle";

const { useUsersVerifyOtpCreateMutation } = backendApi;

interface VerifyOTPFormValues {
  email: string;
  otp: string;
}

type Props = NativeStackScreenProps<RootStackParamList, Routes.MagicLinkSentScreen>;
const EnterOTPScreen = ({
  navigation,
  route: {
    params: { email },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [verifyOTP] = useUsersVerifyOtpCreateMutation();

  const verifyOTPFormSchema = Yup.object().shape({
    email: Yup.string().trim().email("Invalid email").required("Required"),
    otp: Yup.string()
      .required("Required")
      .matches(/^[0-9]{4}$/, t("enter_otp_screen.yup_otp_validation_message")),
  });

  const initialValues: VerifyOTPFormValues = { email, otp: "" };
  const submitForm = async (values: VerifyOTPFormValues): Promise<void> => {
    try {
      const response = await verifyOTP({
        verifyOtpRequest: {
          email: values.email,
          otp: values.otp,
        },
      }).unwrap();

      const authToken = response?.auth_token;
      dispatch(userSlice.actions.storeAuthToken(authToken));
    } catch (e) {
      alert(t("login_screen.failure_recovery"));
    }
  };

  return (
    <View style={styles.wrapper}>
      <Formik initialValues={initialValues} validationSchema={verifyOTPFormSchema} onSubmit={submitForm}>
        {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, errors, dirty, isValid }) => (
          <View style={{ ...styles.container, marginTop: undefined }}>
            <Center>
              <KeyboardAvoidingView behavior="position">
                <Box safeArea flex={1} p="2" py="8" w="90%" mx="auto">
                  <VStack space={6} alignItems="center">
                    <Image source={images.PadlockIcon} marginY={8} size="xl" />
                    <Text fontSize="xl" fontWeight="medium">
                      {t("enter_otp_screen.heading")}
                    </Text>
                    <Text textAlign="center">{t("enter_otp_screen.subheading", { email })}</Text>

                    <FormControl isRequired isInvalid={!_.isEmpty(errors.otp)}>
                      <Input
                        onChangeText={handleChange("otp")}
                        onBlur={handleBlur("otp")}
                        value={values.otp}
                        placeholder={t("enter_otp_screen.otp_placeholder")}
                        testID={"otp-input"}
                        mt="6"
                        py="3.5"
                        keyboardType="number-pad"
                        textContentType="oneTimeCode"
                      />
                      <Center>
                        <FormControl.ErrorMessage>{errors.otp}</FormControl.ErrorMessage>
                      </Center>
                    </FormControl>

                    <Button
                      isLoading={isSubmitting}
                      isDisabled={!dirty || !isValid}
                      onPress={() => handleSubmit()}
                      w="full"
                      testID="otpButton"
                    >
                      {t("enter_otp_screen.submit_button")}
                    </Button>

                    <Center mt={10}>
                      <TroubleLoggingInLink />
                    </Center>

                    <Button mt="16" variant="outline" onPress={() => navigation.goBack()}>
                      {t("enter_otp_screen.back_button")}
                    </Button>
                  </VStack>
                </Box>
              </KeyboardAvoidingView>
            </Center>
          </View>
        )}
      </Formik>
    </View>
  );
};
export default EnterOTPScreen;
