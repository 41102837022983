import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import _ from "lodash";
import { Button, Icon, IconButton, Input, Spinner, useTheme } from "native-base";
import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import type { ViewStyle } from "react-native";
import { Image, ScrollView, StyleProp, Text, TouchableOpacity, useWindowDimensions, View } from "react-native";
import { NavigationState, Route, SceneMap, SceneRendererProps, TabBar, TabView } from "react-native-tab-view";
import { useDispatch, useSelector } from "react-redux";

import { CommonNumberInputWithPlusMinusButtons, CommonStepIndex } from "../commons";
import MacroProgressWidget from "../components/RecipeMacrosItem";
import { commonStyles, Images, IS_MOBILE_PLATFORM, isDesktopScreen, Routes, Scale, VerticalScale } from "../constants";
import { MACRO_NAMES } from "../constants/data";
import { getPlannerData } from "../helpers/diaryHelpers";
import {
  createIngredientDescriptionComponent,
  getServingDescriptionText,
  isInstagramExternalSource,
} from "../helpers/foodHelpers";
import {
  formatMealType,
  formatNumberAsDecimal,
  formatNumberAsWholeNumber,
  getRecipeMealImage,
  openURL,
} from "../helpers/generalHelpers";
import { getDislikedRecipe, getFavouritedRecipe } from "../helpers/userHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type {
  Food,
  GeneratedRecipeMeal,
  GeneratedRecipeMealIngredient,
  Ingredient,
  IngredientPostRequest,
  RecipeMeal,
  SuggestedServing,
  UserFavouriteRecipe,
} from "../services/backendTypes";
import logger from "../services/logger";
import {
  userDislikeRecipesSelector,
  userFavouriteRecipesSelector,
  userSelector,
  userSlice,
  viewAsUserSelector,
} from "../slices/userSlice";
import type { MacroName, RecipeMacrosItemType } from "../types";
import styles from "./RecipeDetailScreenStyle";

const {
  useUsersUserfavouriterecipeCreateMutation,
  useUsersUserdislikerecipeCreateMutation,
  useUsersUserfavouriterecipeDestroyMutation,
  useUsersUserdislikerecipeDestroyMutation,
  useUsersUserfavouriterecipeListQuery,
  useUsersUserdislikerecipeListQuery,
  useFoodIngredientPartialUpdateMutation,
  useFoodRecipeMealRetrieveQuery,
  useFoodIngredientCreateMutation,
  useFoodIngredientDestroyMutation,
  useFoodRecipeMealPartialUpdateMutation,
  useFoodRecipeTemplatePartialUpdateMutation,
  usePlannerEditRecipeMealPortionsUpdateMutation,
} = backendApi;

type GeneratedOrRealIngredient = GeneratedRecipeMealIngredient | Ingredient;

const areIngredientsEqual = (a: GeneratedOrRealIngredient, b: GeneratedOrRealIngredient): boolean => {
  if ("modified" in a && "modified" in b) {
    // Ingredient, not GeneratedRecipeMealIngredient
    return a.id === b.id && a.modified === b.modified;
  }

  if (a.quantity !== b.quantity) {
    return false;
  }

  if (a.suggested_serving !== b.suggested_serving) {
    return false;
  }

  return true;
};

type IngredientComponentProps = {
  ingredientIndex: number;
  ingredient: GeneratedRecipeMealIngredient | Ingredient;
  portions: number;
  editable: boolean;
  onEditIngredientQuantity?: (ingredient: Ingredient, quantity: number) => Promise<void>;
  t: TFunction;
  quantityInputState: string;
  setQuantityInputState: (value: string) => void;
  onPressIngredientSwapButton: () => void;
  isLoading: boolean;
};
export const IngredientComponent = ({
  ingredientIndex,
  ingredient,
  portions,
  editable,
  onEditIngredientQuantity,
  t,
  quantityInputState,
  setQuantityInputState,
  onPressIngredientSwapButton,
  isLoading,
}: // NOTE: This is not in scope for the initial release
// onSwapIngredient: () => void
IngredientComponentProps): JSX.Element => {
  const theme = useTheme();

  const user = useSelector(userSelector);

  if (!ingredient.suggested_serving) {
    throw new Error("Suggested serving is missing");
  }

  const getIndividualMacroForDisplay = (value: number): string =>
    formatNumberAsWholeNumber(value * ingredient.quantity);

  const kcalDescription = `${getIndividualMacroForDisplay(ingredient.suggested_serving.kcal)} ${t("general.kcal")}`;
  const proteinDescription = `${getIndividualMacroForDisplay(ingredient.suggested_serving.protein)}g ${t(
    "general.protein"
  )}`;
  const macrosDescription = `${kcalDescription}, ${proteinDescription}`;

  const { servingInGrams, nonEditableDescription, ingredientInGrams, isServingInGrams } = getServingDescriptionText(
    ingredient.suggested_serving,
    ingredient
  );

  const onBlurIngredientInput = async (): Promise<void> => {
    if (!quantityInputState) {
      // NOTE: An empty string means there has been no change
      return;
    }

    const quantity = parseFloat(quantityInputState.replace(",", "."));

    if (Number.isNaN(quantity)) {
      alert(t("recipe_detail.ingredient_quantity_input.not_a_number_error_text"));
      return;
    }

    if (quantity === 0) {
      alert(t("recipe_detail.ingredient_quantity_input.delete_with_delete_button_error_text"));
      return;
    }

    // TODO: we only edit RecipeMeals, not GeneratedRecipeMeals so
    // ingredient not of type GeneratedRecipeMealIngredient
    // It would be good if we could enforce a type check when this screen loads
    if (!onEditIngredientQuantity) throw new Error("onEditIngredientQuantity undefined");

    await onEditIngredientQuantity(ingredient as Ingredient, quantity);
  };

  const onPressDeleteIngredient = async (): Promise<void> => {
    if (!onEditIngredientQuantity) {
      throw new Error("onEditIngredientQuantity undefined");
    }
    await onEditIngredientQuantity(ingredient as Ingredient, 0);
  };

  return (
    <View
      key={ingredientIndex}
      style={styles.ingredientRow}
      testID={`recipeDetailIngredient-component-${ingredientIndex}`}
      nativeID={`recipeDetailIngredient-component-${ingredientIndex}`}
    >
      <View style={{ width: "60%" }}>
        <Text style={{ color: theme.colors.gray["500"], fontSize: 16, fontWeight: "500" }} testID="name">
          {ingredient.name}
        </Text>
        <Text style={{ color: theme.colors.gray["500"] }} testID="macrosDescription">
          {macrosDescription}
        </Text>
      </View>

      <View style={{ width: "40%" }}>
        {/* NOTE: This is currently not in scope */}
        {/* <CommonIconButton onPress={onSwapIngredient} source={Images.SwapIngredient} size={43} /> */}
        {editable ? (
          <>
            <Input
              selectTextOnFocus
              keyboardType="numeric"
              returnKeyType="done"
              defaultValue={formatNumberAsDecimal(ingredient.quantity, 2)}
              testID={`ingredient-quantity-input-${ingredientIndex}`}
              nativeID={`ingredient-quantity-input-${ingredientIndex}`}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onBlur={onBlurIngredientInput}
              onChangeText={(text) => setQuantityInputState(text.replace(",", "."))}
              InputRightElement={
                <IconButton
                  isDisabled={isLoading}
                  icon={<Icon as={Ionicons} name="trash-outline" />}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onPress={onPressDeleteIngredient}
                  testID={`ingredient-${ingredientIndex}-delete-button`}
                />
              }
              InputLeftElement={
                <IconButton
                  isDisabled={isLoading}
                  ml="1"
                  size={"sm"}
                  onPress={onPressIngredientSwapButton}
                  _icon={{
                    as: Ionicons,
                    name: "swap-vertical-outline",
                  }}
                  testID={`recipeDetailIngredient-swap-button-${ingredientIndex}`}
                />
              }
            />
            {createIngredientDescriptionComponent(ingredient, theme, user)}
          </>
        ) : (
          <View testID="nonEditableDescription">
            <Text style={{ color: theme.colors.gray["500"] }}>{nonEditableDescription}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export const MemoizedIngredientComponent = React.memo(IngredientComponent, (prevProps, nextProps): boolean =>
  areIngredientsEqual(prevProps.ingredient, nextProps.ingredient)
);

type MealForEditScreenBase = GeneratedRecipeMeal | RecipeMeal;
type MealForEditScreen = MealForEditScreenBase & {
  portions_to_plan?: number;
};

const IngredientsRoute = ({
  editable,
  bufferedRecipeMeal,
  onEditPortions,
  onEditIngredientQuantity,
  handleAddIngredient,
  handleEditIngredient,
  showLoading,
}: {
  editable: boolean;
  bufferedRecipeMeal: MealForEditScreen;
  onEditPortions: (portions: number) => Promise<void>;
  onEditIngredientQuantity: (ingredient: Ingredient, quantity: number) => Promise<void>;
  handleAddIngredient: () => void;
  handleEditIngredient: (ingredient: Ingredient) => void;
  showLoading: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [allIngredientQuantityInputState, setAllIngredientQuantityInputState] = useState<{
    [ingredientIndex: number]: string;
  }>({});

  return (
    // NOTE: Not implemented yet
    // const onSwapIngredient = (): void => {
    //   navigation.push(Routes.FoodSearchScreen, {
    //     searchBarTitle: "Search for replacement",
    //   });
    // };

    <View style={commonStyles.container}>
      <View
        style={{
          marginTop: VerticalScale(32),
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text style={{ fontSize: 18, fontWeight: "500" }}>{t("recipe_detail.number_of_people_title")}</Text>

        <CommonNumberInputWithPlusMinusButtons
          editable={false}
          value={bufferedRecipeMeal.portions_to_plan || bufferedRecipeMeal.portions}
          min={1}
          step={1}
          onChange={onEditPortions}
          testIdPrefix={"number-of-people"}
        />
      </View>
      <View>
        {/* NOTE: I think the props code is correct (and VSCode correctly picks it up)
          but eslint on the command line throws an error */}
        {/* eslint-disable-next-line react/prop-types */}
        {bufferedRecipeMeal?.ingredients.map((ingredient, ingredientIndex) => {
          const quantityInputState = allIngredientQuantityInputState[ingredientIndex] || "";

          const setQuantityInputState = (newQuantityInputState: string): void => {
            setAllIngredientQuantityInputState({
              ...allIngredientQuantityInputState,
              [ingredientIndex]: newQuantityInputState,
            });
          };

          return (
            // TODO: Memoize this but be careful with the input state
            <IngredientComponent
              key={ingredientIndex}
              {...{
                ingredientIndex,
                ingredient,
                portions: bufferedRecipeMeal.portions,
                editable,
                onEditIngredientQuantity,
                t,
                quantityInputState,
                setQuantityInputState,
                onPressIngredientSwapButton: () => handleEditIngredient(ingredient as Ingredient),
                isLoading: showLoading,
              }}
            />
          );
        })}
      </View>

      {editable ? (
        <Button
          borderColor="gray.100"
          variant="outline"
          testID={"recipeDetailAddIngredient-button"}
          onPress={handleAddIngredient}
          mb={"40px"}
        >
          <Text style={{ color: theme.colors.gray["600"] }}>{`+ ${t(
            "recipe_detail.add_an_ingredient_button_text"
          )}`}</Text>
        </Button>
      ) : null}
    </View>
  );
};

const areIngredientsArrayEqual = (
  ingredientsA: GeneratedRecipeMealIngredient[] | Ingredient[],
  ingredientsB: GeneratedRecipeMealIngredient[] | Ingredient[]
): boolean => {
  if (ingredientsA.length !== ingredientsB.length) {
    return false;
  }

  return _.every(ingredientsA, (ingredientA: GeneratedRecipeMealIngredient | Ingredient, ingredientIndex: number) => {
    const ingredientB = ingredientsB[ingredientIndex];

    if (!ingredientB) return false;

    return areIngredientsEqual(ingredientA, ingredientB);
  });
};

// NOTE: There still appear to be some additional re-renders despite the use of React.memo.
const MemoizedIngredientsRoute = React.memo(
  IngredientsRoute,
  (prevProps, nextProps) =>
    // NOTE: This is because `id` does not exist on GeneratedRecipeMeal
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prevProps.bufferedRecipeMeal.id &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    prevProps.bufferedRecipeMeal.id === nextProps.bufferedRecipeMeal.id &&
    areIngredientsArrayEqual(prevProps.bufferedRecipeMeal.ingredients, nextProps.bufferedRecipeMeal.ingredients)
);

type Props = NativeStackScreenProps<RootStackParamList, Routes.RecipeDetailScreen>;
const RecipeDetailScreen = ({
  navigation,
  route: {
    params: {
      recipeMeal,
      mealSlotSpecification,
      onSelect,
      viewOnly = false,
      editable = false,
      recipeTemplateEditable = false,
    },
  },
}: Props): JSX.Element => {
  const { t } = useTranslation();
  // TODO: This is commented out because it is not implemented yet
  // const [searchText, setSearchText] = useState("");
  // const [, setSelectedCategory] = useState();
  // const [, setModalVisible] = useState(false);

  const theme = useTheme();

  const dispatch = useDispatch();
  // TODO: This should be removed to avoid potential errors but it is working at the moment
  const viewAsUser = useSelector(viewAsUserSelector);
  const realUser = useSelector(userSelector);
  const user = viewAsUser || realUser;

  const favouritedRecipes = useSelector(userFavouriteRecipesSelector);
  const dislikedRecipes = useSelector(userDislikeRecipesSelector);

  const layout = useWindowDimensions();
  const isDesktop = isDesktopScreen();
  const recipeTemplate = recipeMeal.recipe_template;

  let userFavouriteRecipe: UserFavouriteRecipe | undefined;
  let userDislikeRecipe: UserFavouriteRecipe | undefined;

  if (!recipeTemplate.id) {
    logger.error("Recipe template id is missing for recipeMeal:", recipeMeal);
  } else {
    userFavouriteRecipe = getFavouritedRecipe(favouritedRecipes, recipeTemplate.id);

    userDislikeRecipe = getDislikedRecipe(dislikedRecipes || [], recipeTemplate.id);
  }

  // eslint-disable-next-line no-prototype-builtins
  if (editable && !recipeMeal.hasOwnProperty("id")) {
    throw new Error("Tried to display not editable recipe details in edit mode");
  }

  const [index, setIndex] = useState(0);

  if (!user) {
    throw new Error("user is undefined");
  }

  const {
    isFetching: isLoadingUserFavoriteRecipe,
    data: paginatedUserFavouriteRecipeList,
    error: errorUserFavouriteRecipeList,
    refetch: refetchUserFavouriteRecipeList,
  } = useUsersUserfavouriterecipeListQuery({
    user: user.id,
    recipe: recipeTemplate.id,
  });

  const {
    isFetching: isLoadingUserDislikeRecipe,
    data: paginatedUserDislikeRecipeList,
    error: errorUserDislikeRecipeList,
    refetch: refetchUserDislikeRecipeList,
  } = useUsersUserdislikerecipeListQuery({
    user: user.id,
    recipe: recipeTemplate.id,
  });

  const [deleteUserFavouriteRecipeOnBackend, { isLoading: isLoadingFavDelete }] =
    useUsersUserfavouriterecipeDestroyMutation();
  const [createNewUserFavouriteRecipe, { isLoading: isLoadingFavCreate }] = useUsersUserfavouriterecipeCreateMutation();
  const [deleteUserDislikeRecipeOnBackend, { isLoading: isLoadingDislikeDelete }] =
    useUsersUserdislikerecipeDestroyMutation();
  const [createNewUserDislikeRecipe, { isLoading: isLoadingDislikeCreate }] = useUsersUserdislikerecipeCreateMutation();

  const [updateRecipeMealIngredient, { isLoading: isLoadingIngredientUpdate }] =
    useFoodIngredientPartialUpdateMutation();
  const [updateRecipeMeal, { isLoading: isLoadingRecipeMealUpdate }] = useFoodRecipeMealPartialUpdateMutation();
  const { refetchCalendarDays } = getPlannerData();
  const [createIngredient, { isLoading: isLoadingIngredientCreate }] = useFoodIngredientCreateMutation();
  const [deleteIngredient, { isLoading: isLoadingIngredientDelete }] = useFoodIngredientDestroyMutation();
  const [updateRecipeTemplate, { isLoading: isLoadingRecipeTemplateUpdate }] =
    useFoodRecipeTemplatePartialUpdateMutation();
  const [updateRecipeMealPortions, { isLoading: isLoadingUpdateRecipeMealPortions }] =
    usePlannerEditRecipeMealPortionsUpdateMutation();

  // TODO: rename
  const [bufferedRecipeMeal, setBufferedRecipeMeal] = useState<MealForEditScreen>(recipeMeal);

  const routes: Route[] = [
    { key: "first", title: t("recipe_detail.ingredients_tab_title"), testID: "recipeDetailTab-ingredients" },
  ];

  const isAuserGeneratedMeal = bufferedRecipeMeal.recipe_template.source_provider === "USER_GENERATED";

  const DUTCH_MIGRATED_LEGACY_MEAL_DIRECTION = "Zelfgemaakte maaltijd";
  const ENGLISH_MIGRATED_LEGACY_MEAL_DIRECTION = "Self-created meal";

  const isMigratedLegacyMeal =
    _.size(bufferedRecipeMeal.directions) === 1 &&
    [DUTCH_MIGRATED_LEGACY_MEAL_DIRECTION, ENGLISH_MIGRATED_LEGACY_MEAL_DIRECTION].includes(
      bufferedRecipeMeal.directions?.[0]?.text || ""
    );

  const hasDirections = !_.isEmpty(bufferedRecipeMeal.directions);
  if (hasDirections && !isMigratedLegacyMeal) {
    routes.push({
      key: "second",
      title: t("recipe_detail.preparation_steps_tab_title"),
      testID: "recipeDetailTab-preparationSteps",
    });
  }

  const {
    isFetching: isLoadingRecipeMealQuery,
    error: errorRetrievingRecipeMeal,
    data: recipeMealOnBackend,
    refetch: refetchRecipeMeal,
  } = useFoodRecipeMealRetrieveQuery(
    // TODO: How can we legitimately define a hook when bufferedRecipeMeal may not
    // have an id
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { id: bufferedRecipeMeal.id },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    { skip: !bufferedRecipeMeal.id }
  );

  const shouldShowLoadingSpinner =
    isLoadingFavDelete ||
    isLoadingFavCreate ||
    isLoadingDislikeDelete ||
    isLoadingDislikeCreate ||
    isLoadingIngredientUpdate ||
    isLoadingRecipeMealUpdate ||
    isLoadingIngredientCreate ||
    isLoadingIngredientDelete ||
    isLoadingRecipeMealQuery ||
    isLoadingRecipeTemplateUpdate ||
    isLoadingUserFavoriteRecipe ||
    isLoadingUserDislikeRecipe ||
    isLoadingUpdateRecipeMealPortions;

  useEffect(() => {
    if (recipeMealOnBackend && recipeMealOnBackend.id) {
      setBufferedRecipeMeal(recipeMealOnBackend);
    }
  }, [recipeMealOnBackend]);

  useEffect(() => {
    if (!isLoadingUserFavoriteRecipe && !errorUserFavouriteRecipeList && recipeTemplate.id) {
      if (
        paginatedUserFavouriteRecipeList &&
        paginatedUserFavouriteRecipeList.results &&
        paginatedUserFavouriteRecipeList.results[0]
      ) {
        dispatch(
          userSlice.actions.storeUserFavouriteRecipe({
            [recipeTemplate.id]: paginatedUserFavouriteRecipeList.results[0],
          })
        );
      } else {
        dispatch(userSlice.actions.deleteUserFavouriteRecipe({ recipeTemplateId: recipeTemplate.id }));
      }
    }
  }, [paginatedUserFavouriteRecipeList]);

  useEffect(() => {
    if (!isLoadingUserDislikeRecipe && !errorUserDislikeRecipeList && recipeTemplate.id) {
      if (
        paginatedUserDislikeRecipeList &&
        paginatedUserDislikeRecipeList.results &&
        paginatedUserDislikeRecipeList.results[0]
      ) {
        dispatch(
          userSlice.actions.storeUserDislikeRecipe({
            [recipeTemplate.id]: paginatedUserDislikeRecipeList.results[0],
          })
        );
      } else {
        dispatch(userSlice.actions.deleteUserDislikeRecipe({ recipeTemplateId: recipeTemplate.id }));
      }
    }
  }, [paginatedUserDislikeRecipeList]);

  const refreshRecipeMealData = (): void => {
    // NOTE: refetching calendar data is an expensive operation to do for simply updating a recipeMeal,
    // but we do it for now and can rethink this later if there is a performance issue
    refetchRecipeMeal();
    refetchCalendarDays(); // TODO: do we need invalidate anything before doing this?
  };

  const onEditPortions = async (portions: number): Promise<void> => {
    if (!(bufferedRecipeMeal as RecipeMeal).id || !editable) {
      setBufferedRecipeMeal({ ...bufferedRecipeMeal, portions_to_plan: portions });
      return;
    }

    await updateRecipeMealPortions({
      id: String((bufferedRecipeMeal as RecipeMeal).id),
      recipeMealPotionsRequest: {
        portions,
      },
    });

    refreshRecipeMealData();
  };

  const onEditIngredientQuantity = async (ingredient: Ingredient, quantity: number): Promise<void> => {
    if (!ingredient.id || !editable) {
      throw new Error("Tried to edit a non editable Ingredient or GeneratedRecipeMealIngredient");
    }

    if (isLoadingRecipeMealQuery) {
      logger.warn("Tried to edit an ingredient quantity while recipeMeal is still loading");
      return;
    }

    if (!quantity) {
      await deleteIngredient({ id: ingredient.id });
    } else {
      await updateRecipeMealIngredient({
        id: ingredient.id,
        patchedIngredientRequest: {
          quantity: Math.round(quantity * 20) / 20,
        },
      });
    }

    refreshRecipeMealData();
  };

  const onFavouriteButtonPress = async (): Promise<void> => {
    if (!isLoadingUserFavoriteRecipe) {
      if (recipeTemplate.id) {
        if (userFavouriteRecipe) {
          await deleteUserFavouriteRecipeOnBackend({ id: userFavouriteRecipe.id }).unwrap();
        } else {
          await createNewUserFavouriteRecipe({
            userFavouriteRecipeRequest: { recipe: recipeTemplate.id, user: user.id },
          }).unwrap();
        }
        refetchUserFavouriteRecipeList();
      }
    }
  };

  const onDislikeButtonPress = async (): Promise<void> => {
    if (!isLoadingUserDislikeRecipe) {
      if (recipeTemplate.id) {
        if (userDislikeRecipe) {
          await deleteUserDislikeRecipeOnBackend({ id: userDislikeRecipe.id }).unwrap();
        } else {
          await createNewUserDislikeRecipe({
            userDislikeRecipeRequest: { recipe: recipeTemplate.id, user: user.id },
          }).unwrap();
        }
        refetchUserDislikeRecipeList();
      }
    }
  };

  const onCloseBtn = (): void => {
    navigation.goBack();
  };

  const onChooseProduct = async (
    ingredient: Ingredient | undefined,
    product: Food,
    suggestedServing: SuggestedServing,
    quantity: number
  ): Promise<void> => {
    if (!(bufferedRecipeMeal as RecipeMeal).id || !editable) {
      throw new Error("Tried to edit a non editable RecipeMeal or GeneratedRecipeMeal");
    }

    if (!suggestedServing.id) {
      throw new Error("Suggested serving is not set");
    }

    const ingredientPostRequest: IngredientPostRequest = {
      quantity,
      object_id: (bufferedRecipeMeal as RecipeMeal).id,
      content_type: "recipemeal",
      suggested_serving: suggestedServing.id,
    };

    if (ingredient && ingredient.id) {
      // Update the ingredient
      await updateRecipeMealIngredient({
        id: ingredient.id,
        patchedIngredientRequest: {
          quantity,
          suggested_serving: suggestedServing,
        },
      });
    } else {
      await createIngredient({ ingredientPostRequest });
    }
    refreshRecipeMealData();
    navigation.goBack();
  };

  const handleAddIngredient = (): void => {
    navigation.push(Routes.AddProductStack, {
      screen: Routes.FoodSearchScreen,
      params: {
        onChoose: async (product, suggestedServing, quantity) =>
          onChooseProduct(undefined, product, suggestedServing, quantity),
      },
    });
  };

  const handleEditIngredient = (ingredient: Ingredient): void => {
    if (!editable || !ingredient) {
      throw new Error("Tried to edit a non editable Ingredient or GeneratedRecipeMealIngredient");
    }

    navigation.push(Routes.AddProductStack, {
      screen: Routes.AddIngredientScreen,
      params: {
        ingredient,
        onChoose: async (product, suggestedServing, quantity) =>
          onChooseProduct(ingredient, product, suggestedServing, quantity),
      },
    });
  };

  const PreparationStepRoute = (): JSX.Element => (
    <View style={{ flex: 1, marginVertical: VerticalScale(32) }}>
      <View style={styles.ingredientsListContainer}>
        {/* NOTE: I think the props code is correct (and VSCode correctly picks it up)
        but eslint on the command line throws an error */}
        {/* eslint-disable-next-line react/prop-types */}
        {bufferedRecipeMeal?.directions?.map((direction, directionIndex) => (
          <View key={directionIndex} style={styles.stepContainer}>
            <CommonStepIndex title={`${directionIndex + 1}`} externalStyle={{ marginRight: 10 }} />
            <Text
              style={{ ...commonStyles.bodyText16, color: theme.colors.gray["400"], flex: 1 }}
              testID={`direction-${directionIndex}`}
            >
              {direction.text}
            </Text>
          </View>
        ))}

        {/* TODO: We will need to consider extending the recipe model to
       allow for user tips and user notes on a given recipe */}
        {/* <View style={styles.tipContainer}>
        <Image source={Images.LightbulbGreen} style={styles.tipIcon} />
        <Text style={commonStyles.titleGreenText16}>{t("Tip: Add sweetener to taste")}</Text>
      </View>
      <View style={styles.noteOnRecipe}>
        <Text style={commonStyles.sectionText16Bold}>{"Note on recipe?"}</Text>
      </View> */}
      </View>
    </View>
  );

  const IngredientsListRoute = (): JSX.Element => (
    <MemoizedIngredientsRoute
      {...{
        editable,
        bufferedRecipeMeal,
        onEditPortions,
        onEditIngredientQuantity,
        handleAddIngredient,
        handleEditIngredient,
        showLoading: isLoadingIngredientDelete || isLoadingIngredientUpdate,
      }}
    />
  );

  const renderScene = SceneMap({
    first: IngredientsListRoute,
    second: PreparationStepRoute,
  });

  const isAUserGeneratedMeal = bufferedRecipeMeal.recipe_template.source_provider === "USER_GENERATED";

  const createMacroItem = (macroName: MacroName): RecipeMacrosItemType => {
    let progress = bufferedRecipeMeal[macroName];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isAUserGeneratedMeal && !bufferedRecipeMeal.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      progress = bufferedRecipeMeal.recipe_template[macroName] || 0;
    }

    return {
      id: macroName,
      macroName,
      total: mealSlotSpecification[macroName] ?? 0,
      progress,
      unit: macroName === "kcal" ? "kcal" : "g",
    };
  };
  const nutritionalInformationForRecipe = _.map(MACRO_NAMES, createMacroItem);

  const [submitted, setSubmitted] = useState(false);

  const onEditRecipeTemplate = (): void => {
    navigation.push(Routes.RecipeAddOwnStack, {
      screen: Routes.RecipeAddOwnScreen,
      params: {
        mealSlotSpecification,
        initialRecipeMeal: bufferedRecipeMeal,
        onSave: async () => {
          // Unpublish initial recipe template
          // TODO: while we should have this guard is there a better way to
          //  prevent WEEKMEALS recipe templates being unpublished? Could we enforce that better?
          if (
            bufferedRecipeMeal.recipe_template.source_provider === "USER_GENERATED" &&
            bufferedRecipeMeal.recipe_template.id
          ) {
            await updateRecipeTemplate({
              id: bufferedRecipeMeal.recipe_template.id,
              patchedRecipeTemplateRequest: {
                published: false,
              },
            }).unwrap();
          }
        },
      },
    });
  };
  const isDefaultImg = getRecipeMealImage(recipeMeal).usedDefaultImage;
  const imageStyle = isDefaultImg ? styles.defaultImgContainer : styles.imgContainer;

  const recipeImageComponent = React.useCallback(
    () => (
      <View style={isDesktop ? styles.imgContainerDesktop : styles.imgViewContainer}>
        <Image
          source={getRecipeMealImage(recipeMeal).source}
          resizeMode={getRecipeMealImage(recipeMeal).usedDefaultImage ? "contain" : undefined}
          style={isDesktop ? styles.imgContainerDesktop : imageStyle}
        />
        <View style={styles.closeIconContaier}>
          <TouchableOpacity onPress={onCloseBtn} testID={"closeRecipeDetailScreen-button"}>
            <Image source={Images.CloseIconCircle} style={{ width: Scale(32), height: Scale(32) }}></Image>
          </TouchableOpacity>
        </View>
        <View style={styles.favouriteIconContainer} nativeID={"favouriteButton"}>
          <TouchableOpacity
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={onFavouriteButtonPress}
            disabled={isLoadingUserFavoriteRecipe || isLoadingFavDelete}
            testID={`favourite-button-${bufferedRecipeMeal.recipe_template.id || "MISSING_ID"}`}
          >
            <Icon
              as={MaterialIcons}
              name={userFavouriteRecipe ? "favorite" : "favorite-border"}
              size={Scale(28)}
              color={userFavouriteRecipe ? "red" : "gray"}
            />
          </TouchableOpacity>
        </View>
        <View style={{ ...styles.favouriteIconContainer, right: Scale(60) }} nativeID={"dislikeButton"}>
          <TouchableOpacity
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPress={onDislikeButtonPress}
            disabled={isLoadingUserDislikeRecipe || isLoadingDislikeDelete}
            testID={`dislike-button-${bufferedRecipeMeal.recipe_template.id || "MISSING_ID"}`}
          >
            <Icon
              as={MaterialIcons}
              name={userDislikeRecipe ? "thumb-down" : "thumb-down-off-alt"}
              size={Scale(28)}
              color={userDislikeRecipe ? "blue" : "gray"}
            />
          </TouchableOpacity>
        </View>
      </View>
    ),
    [
      bufferedRecipeMeal.recipe_template.id,
      isDesktop,
      isLoadingFavDelete,
      isLoadingUserFavoriteRecipe,
      userFavouriteRecipe,
      isLoadingDislikeDelete,
      isLoadingUserDislikeRecipe,
      userDislikeRecipe,
    ]
  );

  const handleInstagramIconPress = (url: string): void => {
    openURL(url);
  };

  const recipeInformationComponent = (
    <View style={commonStyles.mainContainer}>
      {Boolean(bufferedRecipeMeal?.recipe_template?.preparation_time_min) && (
        <View style={styles.prepTimeContainer}>
          <Image source={Images.ClockGraIcon} style={styles.clockIcon} />

          <Text style={{ ...commonStyles.smallText14, color: theme.colors.gray["300"] }}>
            {bufferedRecipeMeal?.recipe_template.preparation_time_min}m
          </Text>
        </View>
      )}
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={[commonStyles.titleText20Bold, styles.titleText]}>{bufferedRecipeMeal?.recipe_template.name}</Text>

        {recipeTemplateEditable ? (
          <View style={styles.editIconContaier} nativeID={"editButton"}>
            <TouchableOpacity onPress={onEditRecipeTemplate} testID={"editRecipeTemplateRecipeDetailScreen-button"}>
              <Image source={Images.EditPencilIcon} style={{ width: Scale(18), height: Scale(18) }}></Image>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: Scale(8),
          marginLeft: -Scale(10),
        }}
      >
        {isInstagramExternalSource(bufferedRecipeMeal?.recipe_template?.external_url || "") ? (
          <Button
            onPress={(): void => handleInstagramIconPress(bufferedRecipeMeal?.recipe_template?.external_url || "")}
            leftIcon={<Icon as={Ionicons} name="logo-instagram" />}
            variant="link"
            testID="instagramButton"
          >
            {t("recipe_detail.view_on_instagram")}
          </Button>
        ) : null}
      </View>

      <View
        style={styles.recipeMacrosContainer}
        testID="RecipeDetailScreen-macros-view"
        nativeID="RecipeDetailScreenMacrosView"
      >
        {nutritionalInformationForRecipe.map((macroDetail, macroDetailIndex: number) => (
          <MacroProgressWidget
            key={macroDetailIndex}
            macroDetail={macroDetail}
            showLabel
            verticalMode
            excludeDetail={["fat", "carbohydrates"]}
          />
        ))}
      </View>
    </View>
  );

  const renderTabBar = (
    props: SceneRendererProps & {
      navigationState: NavigationState<Route>;
    }
  ): JSX.Element => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: theme.colors.primary["600"] }}
      style={{ backgroundColor: "#FFF" }}
      activeColor={theme.colors.muted["500"]}
      inactiveColor={theme.colors.muted["500"]}
      renderLabel={({ route: tabRoute, color }) => (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={{ color, fontSize: 16, fontWeight: "bold" }}>{tabRoute.title}</Text>
          {tabRoute.key === "first" && shouldShowLoadingSpinner ? <Spinner ml="2" /> : null}
        </View>
      )}
    />
  );

  const ROW_HEIGHT = 100;
  const calculatedHeight = Math.min(bufferedRecipeMeal.ingredients.length * ROW_HEIGHT, 700);

  let ingredientsAndDirectionsTabsComponentStyle: StyleProp<ViewStyle> = { marginTop: VerticalScale(35) };
  if (IS_MOBILE_PLATFORM) {
    ingredientsAndDirectionsTabsComponentStyle = {
      ...ingredientsAndDirectionsTabsComponentStyle,
      height: isDesktop ? calculatedHeight : undefined,
    };
  }

  const ingredientsAndDirectionsTabsComponent = (
    <View style={[commonStyles.paddingContainer, ingredientsAndDirectionsTabsComponentStyle]}>
      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{
          width: layout.width,
        }}
        renderTabBar={renderTabBar}
        sceneContainerStyle={{ flex: 1 }}
        style={{
          // NOTE: On web there is no extra padding on the bottom of the tab view but
          // on mobile it takes the height literally
          height: IS_MOBILE_PLATFORM ? 1500 : undefined,
          minHeight: calculatedHeight,
        }}
      />
    </View>
  );

  const chooseMealComponent = (
    <View style={[commonStyles.bottomContainer, { bottom: VerticalScale(48) }]}>
      <View style={{ paddingBottom: 20 }}>
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onPress={async () => {
            setSubmitted(true);
            await onSelect(bufferedRecipeMeal.portions_to_plan || 1);
          }}
          isLoading={submitted}
          testID="choose-meal"
        >
          {t("recipe_search.choose_for_meal_button_text", {
            meal: formatMealType(mealSlotSpecification.meal_type, t),
          })}
        </Button>
      </View>
      {/* NOTE: This is not yet implemented */}
      {/* <CommonButton
  title={t("Change recipe")}
  externalTextStyle={commonStyles.sectionText16Bold}
  lightMode
  externalContainerStyle={{ marginTop: VerticalScale(8) }}
  onPress={() => {
    navigation.goBack();
  }}
/> */}
    </View>
  );

  return (
    <View style={{ flex: isDesktop ? 1 : undefined, backgroundColor: "white" }}>
      <ScrollView showsVerticalScrollIndicator={false}>
        {recipeImageComponent()}

        {recipeInformationComponent}

        {ingredientsAndDirectionsTabsComponent}
      </ScrollView>

      {!viewOnly && chooseMealComponent}
    </View>
  );
};
export default RecipeDetailScreen;
