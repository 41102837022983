import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Formik } from "formik";
import _ from "lodash";
import { Button, CheckIcon, Flex, FormControl, Input, Select, Text, View } from "native-base";
import React, { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { NativeSyntheticEvent, TextInputFocusEventData } from "react-native";
import { SafeAreaView } from "react-native";
import * as Yup from "yup";

import { CommonPageHeader } from "../commons";
import { commonStyles, Routes } from "../constants";
import { sortMealSlotSpecificationsByMealMoment } from "../helpers/diaryHelpers";
import { getServingDescriptionText } from "../helpers/foodHelpers";
import { formatMealType, formatNumberAsWholeNumber } from "../helpers/generalHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import backendApi from "../services/backendApi";
import type { Food, SuggestedServing } from "../services/backendTypes";
import styles from "./AddIngredientScreenStyle";

const { useFoodFoodRetrieveQuery } = backendApi;

type Props = NativeStackScreenProps<RootStackParamList, Routes.AddIngredientScreen>;

const QuantityInput = memo(
  ({
    value,
    onChangeText,
    onBlur,
    error,
    t,
  }: {
    value: string;
    onChangeText: (text: string) => void;
    onBlur: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
    error?: string;
    t: (key: string) => string;
  }) => {
    const [localValue, setLocalValue] = React.useState(value);

    const handleLocalChange = (text: string): void => {
      setLocalValue(text);
    };

    const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>): void => {
      onChangeText(localValue);
      onBlur(e);
    };

    return (
      <FormControl isRequired isInvalid={!!error}>
        <FormControl.Label>{t("general.amount")}</FormControl.Label>
        <Input
          autoFocus
          onBlur={handleBlur}
          placeholder={t("general.amount")}
          onChangeText={handleLocalChange}
          value={localValue}
          keyboardType="decimal-pad"
          returnKeyType="done"
          maxLength={10}
          testID={"quantity-input"}
        />
        <FormControl.ErrorMessage>{error}</FormControl.ErrorMessage>
      </FormControl>
    );
  }
);

QuantityInput.displayName = "QuantityInput";

const AddIngredientScreen = ({
  route: {
    params: { food: initialFood, ingredient, onChoose, chooseMealMoment, mealSlotSpecifications },
  },
  navigation,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingFoodQuery,
    error,
    data: returnedFood,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useFoodFoodRetrieveQuery({ id: ingredient?.suggested_serving.food }, { skip: initialFood });

  const [food, setFood] = React.useState<Food | undefined>(initialFood);

  React.useEffect(
    () =>
      // NOTE: Return the function to unsubscribe from the event so it gets removed on unmount
      navigation.addListener("focus", () => {
        // TODO: Pre-select the amount input
      }),
    [navigation]
  );

  React.useEffect(() => {
    if (returnedFood && !isLoadingFoodQuery) {
      setFood(returnedFood);
    }
  }, [returnedFood]);

  const handleQuantityChange = useCallback(
    (setFieldValue: (field: string, value: number | string) => void) =>
      (quantity: string): void => {
        setFieldValue("quantity", quantity.replace(",", "."));
      },
    []
  );

  if (!food && !ingredient) {
    throw new Error("No Food");
  }

  if (chooseMealMoment && !mealSlotSpecifications) {
    throw new Error("No meal slot specifications found");
  }

  const schemaShapeObject = {
    quantity: Yup.number().positive().required("Required"),
    suggestedServingId: Yup.number().positive().integer().required("Required"),
  };

  if (chooseMealMoment) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    schemaShapeObject.mealSlotSpecificationId = Yup.number().positive().integer();
  }

  if (!food) {
    return null;
  }
  if (!food.suggested_servings) {
    return null;
  }

  const header = (
    <CommonPageHeader
      // eslint-disable-next-line max-len
      // TODO: This should be changed depending on if we are adding a single product to the planner or an ingredient to a recipe
      showBackButton={false}
      onPressBack={() => {
        navigation.goBack();
      }}
      onPressClose={() => {
        // NOTE: This leaves the current stack
        navigation.getParent()?.goBack();
      }}
    />
  );

  const ingredientSchema = Yup.object().shape(schemaShapeObject);

  type IngredientSchema = Yup.InferType<typeof ingredientSchema>;

  const getSuggestedServingFromForm = (values: IngredientSchema): SuggestedServing | undefined =>
    _.find(food?.suggested_servings, { id: Number(values.suggestedServingId) });

  const onSubmit = async (values: IngredientSchema): Promise<void> => {
    const suggestedServing = getSuggestedServingFromForm(values);

    if (!suggestedServing) {
      throw new Error(`Could not find the suggested serving with id: ${values.suggestedServingId}`);
    }

    if (chooseMealMoment) {
      if (!mealSlotSpecifications) {
        throw new Error("No meal slot specifications provided to choose from");
      }

      const mealSlot = _.find(mealSlotSpecifications, {
        id: Number(values.mealSlotSpecificationId),
      });

      if (!mealSlot) {
        throw new Error("Could not find meal slot");
      }

      await onChoose(food, suggestedServing, values.quantity, mealSlot);
    } else {
      await onChoose(food, suggestedServing, values.quantity);
    }
  };

  const initialValueSuggestedServingId = ingredient?.suggested_serving?.id || food.suggested_servings[0]?.id || 0;
  const initialQuantity = ingredient?.quantity || 1;
  const initialValues = ingredientSchema.cast({
    quantity: initialQuantity,
    suggestedServingId: initialValueSuggestedServingId,
  });

  return (
    <Formik
      // NOTE: I don't know why this error happens
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={ingredientSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, setFieldValue, errors, isValid }) => {
        const getIndividualMacroForDisplay = (value: number): string =>
          formatNumberAsWholeNumber(value * values.quantity);

        const suggestedServing = getSuggestedServingFromForm(values);

        const kcalDescription = `${getIndividualMacroForDisplay(suggestedServing?.kcal || 0)} ${t("general.kcal")}`;
        const proteinDescription = `${getIndividualMacroForDisplay(suggestedServing?.protein || 0)}g ${t(
          "general.protein"
        )}`;
        const carbsDescription = `${getIndividualMacroForDisplay(suggestedServing?.carbohydrates || 0)}g ${t(
          "general.carbohydrates"
        )}`;
        const fatDescription = `${getIndividualMacroForDisplay(suggestedServing?.fat || 0)}g ${t("general.fat")}`;
        const macrosDescription = `${kcalDescription}, ${proteinDescription}, ${carbsDescription}, ${fatDescription}`;

        const servingInGrams = suggestedServing ? getServingDescriptionText(suggestedServing).servingInGrams : "";

        return (
          <SafeAreaView style={commonStyles.container}>
            {header}
            <View style={commonStyles.mainContainer}>
              <View style={styles.titleContainer}>
                <Text style={commonStyles.titleText24Bold}>{food.name}</Text>
              </View>

              {/* TODO: Only show this if we are editing a recipe */}
              {/* <View style={styles.RecipeMacros}>
                {recipeMacros?.map((item: RecipeMacrosItemType, index: number) => (
                  <RecipeMacrosItem key={index} data={item} showLabel verticalMode />
                ))}
              </View> */}

              <View style={styles.addItemContainer}>
                <View style={styles.unitContainer}>
                  <FormControl isRequired isInvalid={"suggestedServingId" in errors}>
                    <FormControl.Label>{t("general.unit")}</FormControl.Label>
                    <Select
                      // NOTE: This logic is required because the initialValues is not used by the select
                      selectedValue={String(values.suggestedServingId || initialValueSuggestedServingId)}
                      placeholder={t("planner.add_product.select_unit_button_text")}
                      _selectedItem={{
                        endIcon: <CheckIcon size="5" />,
                      }}
                      onValueChange={(itemValue) => setFieldValue("suggestedServingId", itemValue)}
                      testID={"unit-select"}
                    >
                      {food?.suggested_servings?.map((ss: SuggestedServing) => (
                        <Select.Item label={ss.serving_description} value={String(ss.id)} key={ss.id} />
                      ))}
                    </Select>
                    <FormControl.ErrorMessage>{errors.suggestedServingId}</FormControl.ErrorMessage>
                  </FormControl>
                </View>
                <View style={styles.amountContainer}>
                  <QuantityInput
                    value={String(values.quantity)}
                    onChangeText={handleQuantityChange(setFieldValue)}
                    onBlur={() => handleBlur("quantity")}
                    error={errors.quantity}
                    t={t}
                  />
                </View>
              </View>

              <Flex mt="3">
                {servingInGrams ? (
                  <View>
                    <Text color="gray.500">{`(${servingInGrams})`}</Text>
                  </View>
                ) : null}
              </Flex>

              {chooseMealMoment && mealSlotSpecifications && !_.isEmpty(mealSlotSpecifications) ? (
                <View mt="6">
                  <FormControl isRequired isInvalid={"mealSlotSpecificationId" in errors}>
                    <FormControl.Label>{t("recipe_search.meal_type_filter_text")}</FormControl.Label>
                    <Select
                      selectedValue={String(values.mealSlotSpecificationId)}
                      minWidth="200"
                      placeholder={t("planner.item.choose_meal_button_text")}
                      mt={1}
                      onValueChange={(itemValue) => setFieldValue("mealSlotSpecificationId", itemValue)}
                      testID={"chooseMealMomentForProduct-select"}
                    >
                      {sortMealSlotSpecificationsByMealMoment(mealSlotSpecifications).map((mealSlotSpecification) => (
                        <Select.Item
                          key={mealSlotSpecification.id}
                          label={formatMealType(mealSlotSpecification.meal_type, t)}
                          value={String(mealSlotSpecification.id)}
                        />
                      ))}
                    </Select>
                    <FormControl.ErrorMessage>{errors.mealSlotSpecificationId}</FormControl.ErrorMessage>
                  </FormControl>
                </View>
              ) : null}

              {suggestedServing ? (
                <View mt="3">
                  <Text color="gray.500">{macrosDescription}</Text>
                </View>
              ) : null}
            </View>

            <View style={commonStyles.bottomContainer}>
              <Button
                isLoading={isSubmitting}
                isDisabled={!isValid}
                onPress={() => handleSubmit()}
                testID={"foodSearchSubmit"}
                nativeID={"foodSearchSubmit"}
              >
                {t("general.add")}
              </Button>
            </View>
          </SafeAreaView>
        );
      }}
    </Formik>
  );
};

export default AddIngredientScreen;
