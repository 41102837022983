import { StyleSheet } from "react-native";

import { Scale } from "../constants";
import { FontFamily, FontSize } from "../constants/fonts";

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    // backgroundColor: Colors.whiteColor,
  },
  headerTextStyle: {
    fontFamily: FontFamily.medium,
    fontSize: FontSize.body,
    lineHeight: Scale(24),
    // color: Colors.darkgrey2,
    marginLeft: 0,
  },
  itemsTextStyle: {
    fontFamily: FontFamily.medium,
    fontSize: FontSize.body,
    lineHeight: Scale(24),
    // color: Colors.greyTextColor,
  },
  headerContainer: {},
  checkedTextStyle: {
    fontFamily: FontFamily.medium,
    fontSize: FontSize.body,
    textDecorationLine: "line-through",
    lineHeight: Scale(24),
    // color: Colors.bgEmerald,
  },
  listMainView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: Scale(3),
  },
  listSubView: {
    flexDirection: "row",
    flex: 1,
    alignItems: "center",
  },
  kebabImageView: {
    alignSelf: "center",
  },
  allDoneView: {
    alignItems: "center",
    justifyContent: "center",
    height: Scale(192),
    // backgroundColor: "#F7FDFA",
    borderStyle: "solid",
    borderWidth: Scale(2),
    // borderColor: "#B9E9CF",
    borderRadius: Scale(8),
  },
  allDoneText: {
    textAlign: "center",
    marginTop: Scale(13),
    marginRight: Scale(13),
    fontFamily: FontFamily.bold,
    fontSize: FontSize.h6,
    lineHeight: Scale(28),
    // color: Colors.primaryGreenColor,
  },
  headerStyle: {
    fontFamily: FontFamily.bold,
    fontSize: FontSize.h4,
    lineHeight: Scale(32),
    // color: Colors.largeTextColor,
  },
  calenderView: {
    marginVertical: Scale(24),
    flexDirection: "row",
  },
  hyphenView: {
    marginHorizontal: Scale(12),
    borderWidth: Scale(1),
    // borderColor: Colors.borderColor,
    height: Scale(2),
    width: Scale(8),
    alignSelf: "center",
  },
  partitionLine: {
    // backgroundColor: Colors.borderColor,
    marginBottom: Scale(40),
    marginHorizontal: Scale(-30),
    height: Scale(1),
  },
});

export default styles;
