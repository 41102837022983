import type SegmentAnalytics from "@segment/analytics.js-core";
import type { JsonMap } from "@segment/analytics-react-native";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import _ from "lodash";
import platform from "platform-detect";

import analyticsJsonRaw from "../../../analytics.json";
import type { User } from "../services/backendTypes";
import type { AnalyticsEventConstants, AnalyticsHelpers } from "../types";
import { openURL, shouldStartIntercomLauncherHidden } from "./generalHelpers";

const SUPPORT_WEBSITE = "https://help.nutrition-app.com/";

console.log("Loading real Segment snippet because we are on a web platform");
require("../snippets/segment_snippet");

require("../snippets/datadog_snippet");

function configureDatadogRUMbeforeSend(): void {
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  /* eslint-disable @typescript-eslint/no-unsafe-call */

  try {
    // @ts-ignore
    if (window.DD_RUM && window.DD_RUM.init) {
      // @ts-ignore
      window.DD_RUM.init({
        // @ts-ignore
        beforeSend: (event) => {
          // @ts-ignore
          // eslint-disable-next-line no-param-reassign
          event.context.isInsidePWA = platform.pwa;

          return true;
        },
      });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
configureDatadogRUMbeforeSend();

export const analyticsJson: AnalyticsEventConstants = analyticsJsonRaw;

const shouldHideIntercomLauncher = shouldStartIntercomLauncherHidden();
if (shouldHideIntercomLauncher) {
  // NOTE: There is actually an `analytics` global variable after the snippet has been loaded
  // eslint-disable-next-line no-undef
  analytics.page(
    "",
    "",
    {},
    {
      // NOTE: The segment types apparently does not include Intercom options
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Intercom: { hideDefaultLauncher: true },
    }
  );
}

/**
 * We do not return the analytics object directly because if it is not loaded then it will not work.
 */
function getAnalytics(): SegmentAnalytics.AnalyticsJS {
  return window.analytics;
}

function identifyUserInIntercom(userId: string): void {
  getAnalytics().identify(userId);
}

function resetAnalytics(): void {
  getAnalytics().reset();
}

function identifyUserInSegment(user: User): void {
  getAnalytics().identify(user.id);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (window.DD_RUM && window.DD_RUM.setUser) {
    const userToSendToDatadog = user;
    // TODO: Maybe we should enrich this, ie, with coach data

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.DD_RUM.setUser(userToSendToDatadog);
  }
}

function trackPageView(pageName: string, properties?: JsonMap): void {
  getAnalytics().page(pageName, properties);
}

function openIntercomMessenger(): void {
  if (_.isFunction(window.Intercom)) {
    window.Intercom("show");
  } else {
    // Intercom is not loaded, so we open the support website instead
    window.open(SUPPORT_WEBSITE, "_blank");
    openURL(SUPPORT_WEBSITE);

    Sentry.captureMessage("Intercom is not loaded");
  }
}

function trackEvent(eventName: string, properties?: JsonMap): void {
  getAnalytics().track(eventName, properties);
}

function isIntercomLoaded(): boolean {
  return _.isFunction(window.Intercom);
}

/**
 * We use the same interface for analyticsHelpers.ts and analyticsHelpers.native.ts
 */
const analyticsInterface: AnalyticsHelpers = Object.freeze({
  identifyUserInIntercom,
  resetAnalytics,
  identifyUserInSegment,
  trackPageOrScreenView: trackPageView,
  trackEvent,
  openIntercomMessenger,
  isIntercomLoaded,
});

export default analyticsInterface;
