import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, ScrollView, StyleSheet, Text, View } from "react-native";
import { Button, Icon, Input, useTheme } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { calculateMinimumHealthyWeight } from "../../helpers/fitnessCalculationHelpers";
import { onboardingDataSelector, OnboardingState, setPhysicalStats } from "../../slices/onboardingSlice";
import { updateMacrosInState } from "./onboardingHelpers";

interface PhysicalStatsScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const PhysicalStatsScreen: React.FC<PhysicalStatsScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const onboardingData = useSelector(onboardingDataSelector);

  const validationSchema = Yup.object().shape({
    heightInCm: Yup.number()
      .required(t("onboarding.onboarding_5_physical_stats_screen.valid_height"))
      .positive(t("onboarding.onboarding_5_physical_stats_screen.positive_height"))
      .min(120, t("onboarding.onboarding_5_physical_stats_screen.min_height"))
      .max(250, t("onboarding.onboarding_5_physical_stats_screen.max_height")),
    weightInKg: Yup.number()
      .required(t("onboarding.onboarding_5_physical_stats_screen.valid_weight"))
      .positive(t("onboarding.onboarding_5_physical_stats_screen.positive_weight"))
      .min(45, t("onboarding.onboarding_5_physical_stats_screen.min_weight"))
      .max(300, t("onboarding.onboarding_5_physical_stats_screen.max_weight")),
    goalWeight: Yup.number()
      .required(t("onboarding.onboarding_5_physical_stats_screen.valid_goal_weight"))
      .positive(t("onboarding.onboarding_5_physical_stats_screen.positive_goal_weight"))
      .test("minGoalWeight", t("onboarding.onboarding_5_physical_stats_screen.min_goal_weight"), function (value) {
        const { heightInCm } = this.parent;
        return (value || 0) >= calculateMinimumHealthyWeight(heightInCm as number);
      })
      .max(300, t("onboarding.onboarding_5_physical_stats_screen.max_goal_weight")),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    dispatch(setPhysicalStats(values));

    const updatedOnboardingData: OnboardingState = {
      ...onboardingData,
      physicalStats: values,
    };

    updateMacrosInState(dispatch, updatedOnboardingData);

    // If the goal is weight loss then we need to know their target for weight loss
    // FIXME: Ensure that the logic that is currently done on the weekly goal screen is
    // done here if need be (or at least triggered)
    const nextScreen = onboardingData.goal === "LOSE_WEIGHT" ? Routes.WeeklyGoalScreen : Routes.NutritionalPlanScreen;
    navigation.navigate(nextScreen);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({
        heightInCm: onboardingData.physicalStats?.heightInCm || 160,
        weightInKg: onboardingData.physicalStats?.weightInKg || 70,
        goalWeight: onboardingData.physicalStats?.goalWeight || 65,
      })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => (
        <SafeAreaView style={styles.container}>
          <View style={styles.header}>
            <Icon name="arrow-back" size={24} onPress={() => navigation.goBack()} tvParallaxProperties={undefined} />
          </View>
          <ScrollView contentContainerStyle={styles.content}>
            <Icon
              name="robot"
              type="material-community"
              size={64}
              color={theme.colors?.primary}
              tvParallaxProperties={undefined}
            />
            <Text style={styles.title}>{t("onboarding.onboarding_5_physical_stats_screen.title")}</Text>
            <Text style={styles.subtitle}>{t("onboarding.onboarding_5_physical_stats_screen.subtitle")}</Text>

            <Text style={styles.title}>
              <Text style={{ fontSize: styles.title.fontSize * 1.3 }}>{"📏 "}</Text>
              {t("onboarding.onboarding_5_physical_stats_screen.height_title")}
            </Text>
            <View style={styles.inputWrapper}>
              <Input
                placeholder={t("onboarding.onboarding_5_physical_stats_screen.height_placeholder")}
                value={String(values.heightInCm)}
                onChangeText={(text) => setFieldValue("heightInCm", text)}
                keyboardType="numeric"
                containerStyle={styles.inputContainer}
                inputStyle={styles.input}
                errorMessage={errors.heightInCm && touched.heightInCm ? errors.heightInCm : ""}
                autoCompleteType={"off"}
                selectTextOnFocus
                testID="height-input"
              />
              <Text style={styles.unitText}>{t("onboarding.onboarding_5_physical_stats_screen.centimeters")}</Text>
            </View>

            <Text style={styles.title}>
              <Text style={{ fontSize: styles.title.fontSize * 1.3 }}>{"⚖️ "}</Text>
              {t("onboarding.onboarding_5_physical_stats_screen.weight_title")}
            </Text>
            <Text style={styles.subtitle}>{t("onboarding.onboarding_5_physical_stats_screen.weight_subtitle")}</Text>
            <View style={styles.inputWrapper}>
              <Input
                placeholder={t("onboarding.onboarding_5_physical_stats_screen.weight_placeholder")}
                value={String(values.weightInKg)}
                onChangeText={(text) => setFieldValue("weightInKg", text)}
                keyboardType="numeric"
                containerStyle={styles.inputContainer}
                inputStyle={styles.input}
                errorMessage={errors.weightInKg && touched.weightInKg ? errors.weightInKg : ""}
                autoCompleteType={"off"}
                selectTextOnFocus
                testID="weight-input"
              />
              <Text style={styles.unitText}>{t("onboarding.onboarding_5_physical_stats_screen.kilograms")}</Text>
            </View>

            <Text style={styles.title}>
              <Text style={{ fontSize: styles.title.fontSize * 1.3 }}>{"🎯 "}</Text>
              {t("onboarding.onboarding_5_physical_stats_screen.goal_weight_title")}
            </Text>
            <Text style={styles.subtitle}>
              {t("onboarding.onboarding_5_physical_stats_screen.goal_weight_subtitle")}
            </Text>
            <View style={styles.inputWrapper}>
              <Input
                placeholder={t("onboarding.onboarding_5_physical_stats_screen.goal_weight_placeholder")}
                value={String(values.goalWeight)}
                onChangeText={(text) => setFieldValue("goalWeight", text)}
                keyboardType="numeric"
                containerStyle={styles.inputContainer}
                inputStyle={styles.input}
                errorMessage={errors.goalWeight && touched.goalWeight ? errors.goalWeight : ""}
                autoCompleteType={"off"}
                selectTextOnFocus
                testID="goal-weight-input"
              />
              <Text style={styles.unitText}>{t("onboarding.onboarding_5_physical_stats_screen.kilograms")}</Text>
            </View>

            <Button
              title={t("onboarding.onboarding_5_physical_stats_screen.next_button")}
              buttonStyle={styles.nextButton}
              onPress={() => handleSubmit()}
              testID="next-button"
            />
          </ScrollView>
        </SafeAreaView>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  progressText: {
    marginLeft: 16,
    fontWeight: "bold",
  },
  content: {
    alignItems: "center",
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "#777",
    textAlign: "center",
    marginVertical: 8,
  },
  inputWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 8,
  },
  inputContainer: {
    flex: 1,
    maxWidth: "70%",
  },
  input: {
    textAlign: "center",
    color: "#333",
  },
  unitText: {
    fontSize: 16,
    color: "#777",
    marginLeft: 8,
  },
  nextButton: {
    paddingVertical: 12,
    paddingHorizontal: 32,
    marginTop: 16,
    borderRadius: 8,
    width: "80%",
    alignSelf: "center",
  },
});

export default PhysicalStatsScreen;
