import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { hasUserCompletedTheirDiyNutritionPlanningFormCompletely } from "../../helpers/userHelpers";
import type { MainGoalEnum } from "../../services/backendTypes";
import { onboardingDataSelector, setGoal } from "../../slices/onboardingSlice";
import { userSelector } from "../../slices/userSlice";
import { GoalEnum } from "../../types";
import SelectionScreen, { Option } from "./SelectionScreen";

interface GoalSelectionScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const GoalSelectionScreen: React.FC<GoalSelectionScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const onboardingData = useSelector(onboardingDataSelector);

  const goals: Option[] = [
    {
      label: t(`onboarding.onboarding_2_goal_screen.${GoalEnum.LOSE_WEIGHT}`),
      description: t(`onboarding.onboarding_2_goal_screen.${GoalEnum.LOSE_WEIGHT}_description`),
      value: GoalEnum.LOSE_WEIGHT,
      icon: "🔥", // Fire
      testID: `goal-option-${GoalEnum.LOSE_WEIGHT}`,
    },
    {
      label: t(`onboarding.onboarding_2_goal_screen.${GoalEnum.GAIN_MUSCLE}`),
      description: t(`onboarding.onboarding_2_goal_screen.${GoalEnum.GAIN_MUSCLE}_description`),
      value: GoalEnum.GAIN_MUSCLE,
      icon: "💪", // Flexed biceps
      testID: `goal-option-${GoalEnum.GAIN_MUSCLE}`,
    },
    {
      label: t(`onboarding.onboarding_2_goal_screen.${GoalEnum.RECOMPOSITION}`),
      description: t(`onboarding.onboarding_2_goal_screen.${GoalEnum.RECOMPOSITION}_description`),
      value: GoalEnum.RECOMPOSITION,
      icon: "⚖️", // Balance scale
      testID: `goal-option-${GoalEnum.RECOMPOSITION}`,
    },
  ];

  const validationSchema = Yup.object().shape({
    selectedGoal: Yup.mixed<MainGoalEnum>().required(t("onboarding.onboarding_2_goal_screen.select_at_least_one_goal")),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    dispatch(setGoal(values.selectedGoal as MainGoalEnum));
    navigation.navigate(Routes.ActivityLevelScreen);
  };

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({ selectedGoal: onboardingData.goal || "LOSE_WEIGHT" })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, errors, touched, values }) => (
        <SelectionScreen
          title={t("onboarding.onboarding_2_goal_screen.tell_us_about_your_goal")}
          subtitle={t("onboarding.onboarding_2_goal_screen.calculate_macro_and_calorie_needs")}
          options={goals}
          multipleSelection={false}
          selectedOptions={[values.selectedGoal]}
          setSelectedOptions={(options: string[]) => {
            setFieldValue("selectedGoal", options[0]);
          }}
          onPressNext={handleSubmit}
          onPressBack={
            user && hasUserCompletedTheirDiyNutritionPlanningFormCompletely(user)
              ? () => navigation.goBack()
              : undefined
          }
          errorMessage={errors.selectedGoal && touched.selectedGoal ? errors.selectedGoal : ""}
        />
      )}
    </Formik>
  );
};

export default GoalSelectionScreen;
