import { connectActionSheet } from "@expo/react-native-action-sheet";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import type { EventType } from "expo-linking";
import * as Linking from "expo-linking";
import moment from "moment";
import { Text } from "native-base";
import platform from "platform-detect";
import React, { useEffect, useRef } from "react";
import type { EmitterSubscription } from "react-native";
import { useDispatch, useSelector } from "react-redux";

import { isDesktopScreen, Routes } from "../constants";
import analyticsInterface from "../helpers/analyticsHelpers";
import { getAppName } from "../helpers/generalHelpers";
import { sendUpdateRequestToIntercom } from "../helpers/intercomHelpers";
import { logout } from "../helpers/logout";
import { getHostname, getManagedCustomerPortalSubdomain, isUserLoggedIn } from "../helpers/userHelpers";
import {
  AddGenderScreen,
  AddLifeStyleScreen,
  AddMainGoalScreen,
  AddMotivationGoalScreen,
  CoachModeClientInfoScreen,
  CoachModeViewClientsScreen,
  DiaryViewScreen,
  EmailConfirmationSent,
  EmailConfirmed,
  GetStartedScreen,
  IngredientSearchScreen,
  LoginScreen,
  NutritionPlanScreen,
  OnboardingScreen,
  PersonalDetailsScreen,
  PlanScreen,
  RecipeInspirationScreen,
  RecipeSearchScreen,
  RegisterScreen,
} from "../screens";
import AddIngredientScreen from "../screens/AddIngredientScreen";
import BarcodeScannerScreen from "../screens/BarcodeScannerScreen";
import CheckoutFailureScreen from "../screens/checkout/CheckoutFailureScreen";
import CheckoutScreen from "../screens/checkout/CheckoutSceen";
import {
  CHECKOUT_PRICE_ID_PARAM_NAME,
  CHECKOUT_QUERY_PARAM_NAME,
  CheckoutFlowState,
  CheckoutParamStates,
  CheckoutState,
} from "../screens/checkout/constants";
import ChooseMealMomentsTab2 from "../screens/createNutritionDayPlan/ChooseMealMomentsTab2";
import EditMealMomentsTab3 from "../screens/createNutritionDayPlan/EditMealMomentsTab3";
import EnergyBalanceTab1 from "../screens/createNutritionDayPlan/EnergyBalanceTab1";
import FineTuningTab4 from "../screens/createNutritionDayPlan/FineTuningTab4";
import EditUserProfileScreen from "../screens/EditUserProfileScreen";
import EnterOTPScreen from "../screens/EnterOTPScreen";
import FinishPlanningScreen from "../screens/FinishPlanningScreen";
import GroceryListScreen from "../screens/GroceryListScreen";
import MagicLinkSentScreen from "../screens/MagicLinkSentScreen";
import SignUpScreen from "../screens/New_DIY_form/Onboarding_1_SignUpScreen";
import DIY1GoalScreen from "../screens/New_DIY_form/Onboarding_2_GoalScreen";
import ActivityLevelScreen from "../screens/New_DIY_form/Onboarding_3_ActivityLevelScreen";
import BiometricDataScreen from "../screens/New_DIY_form/Onboarding_4_BiometricDataScreen";
import PhysicalStatsScreen from "../screens/New_DIY_form/Onboarding_5_PhysicalStatsScreen";
import WeeklyGoalScreen from "../screens/New_DIY_form/Onboarding_6_WeeklyGoalScreen";
import ModifyNutritionalPlanScreen from "../screens/New_DIY_form/Onboarding_7_ModifyNutritionPlanScreen";
import NutritionalPlanScreen from "../screens/New_DIY_form/Onboarding_7_NutritionPlanScreen";
import MealTimesScreen from "../screens/New_DIY_form/Onboarding_8_MealTimesScreen";
import DietSelectionScreen from "../screens/New_DIY_form/Onboarding_8b_DietSelectionScreen";
import IntoleranceScreen from "../screens/New_DIY_form/Onboarding_9_IntoleranceScreen";
import IngredientDislikesScreen from "../screens/New_DIY_form/Onboarding_10_IngredientDislikesScreen";
import PostOnboardingScreen from "../screens/New_DIY_form/Onboarding_11_PostOnboardingScreen";
import RegistrationScreen from "../screens/New_DIY_form/RegistrationScreen";
import PWASignupScreen from "../screens/PWASignupScreen";
import RecipeAddOwnScreen from "../screens/RecipeAddOwnScreen";
import RecipeDetailScreen from "../screens/RecipeDetailScreen";
import backendApi from "../services/backendApi";
import type { TokenCreate } from "../services/backendTypes";
import logger from "../services/logger";
import { brandSlice } from "../slices/brandSlice";
import { plannerSlice } from "../slices/plannerSlice";
import {
  authTokenSelector,
  diyFormSelector,
  getAppLastOpenedSelector,
  hasUserDeferredPwaPrompt,
  userSelector,
  userSlice,
} from "../slices/userSlice";
import BottomNavigationStack from "./BottomNavigationStack";
import type { RootStackParamList } from "./NavigationStackParams";

const { useUsersAuthTokenLoginCreateMutation, useUsersAuthUsersMeRetrieveQuery, useUsersBrandRetrieveQuery } =
  backendApi;

const { identifyUserInSegment, identifyUserInIntercom } = analyticsInterface;

const customSupportSubdomain = getManagedCustomerPortalSubdomain(getHostname());

const Stack = createNativeStackNavigator<RootStackParamList>();
const AddProductStack = createNativeStackNavigator();
const AddProductByBarcodeStack = createNativeStackNavigator();
const AddRecipeTemplateStack = createNativeStackNavigator();
const CreateOwnRecipeTemplateStack = createNativeStackNavigator();
const EditNutritionPlanStack = createNativeStackNavigator();

const defaultScreenOptions = { headerShown: false };

const appName = getAppName();
const parentStackNavigatorScreenOptions = { ...defaultScreenOptions, title: appName };

type UrlConfig = {
  queryParams: {
    magic_link_token: string;
    [CHECKOUT_QUERY_PARAM_NAME]: CheckoutParamStates;
    [CHECKOUT_PRICE_ID_PARAM_NAME]: string;
  };
};

const TIMEOUT_FOR_ANALYTICS_TO_LOAD_IN_MS = 2000;

function NavigationStackComponent(): JSX.Element {
  const dispatch = useDispatch();

  const [checkoutState, setCheckoutState] = React.useState<CheckoutFlowState>(CheckoutState.NotInCheckout);
  // TODO: Once we fix deep linking for the checkout, this should just be passed using the Linking API.
  const [checkoutPriceId] = React.useState<string>("");

  // NOTE: If we are not using a custom support subdomain then there is no need to retrieve the url
  const {
    data: brand,
    isError,
    isLoading: isLoadingBrandQuery,
    error,
  } = useUsersBrandRetrieveQuery({ subdomain: customSupportSubdomain }, { skip: !customSupportSubdomain });

  if (isError) {
    console.error(error);

    Sentry.captureException(error);
  }

  useEffect(() => {
    if (brand) {
      dispatch(brandSlice.actions.storeBrandWhileLoggedOut(brand));
    }

    dispatch(brandSlice.actions.setIsLoadingBrand(isLoadingBrandQuery));
  }, [brand, isLoadingBrandQuery]);

  // NOTE: This is the self-service checkout. It is disabled for now.
  // const inCheckoutFlow = checkoutState !== CheckoutState.NotInCheckout;
  const inCheckoutFlow = false;

  const navigationRef = useNavigationContainerRef();
  const routeNameRef = useRef("");

  function dispatchAuthTokenToStoreIfPresent(authTokenResponse: TokenCreate): void {
    if (authTokenResponse) {
      // NOTE: TokenCreate is the wrong response, we should fix the OpenAPI schema generation
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const authTokenFromRequest: string = authTokenResponse?.auth_token;
      dispatch(userSlice.actions.storeAuthToken(authTokenFromRequest));
    }
  }

  // TODO: We should add a spinner/loading component on this screen.
  // In fact, we should probably add a generic one which can be used on all screens
  const [getAuthTokenFromMagicLinkPost, { isLoading: isLoadingMagicTokenExchange, data: authTokenResponse }] =
    useUsersAuthTokenLoginCreateMutation();

  const eventListenerAddedResultRef = useRef<EmitterSubscription | null>(null);

  async function handleUrlParams(url: Linking.ParsedURL): Promise<void> {
    // logger.debug("Inside handleUrlParams with: ", url);

    if (eventListenerAddedResultRef && eventListenerAddedResultRef.current) {
      eventListenerAddedResultRef.current.remove();
    } else {
      Sentry.captureMessage("eventListenerAddedResultRef.current was null");
    }

    if (!url.queryParams) {
      console.log("No query params found in url");
      return;
    }

    const hasCheckoutQueryParam = Boolean(url.queryParams[CHECKOUT_QUERY_PARAM_NAME]);
    if ("magic_link_token" in url.queryParams) {
      if (typeof url.queryParams.magic_link_token !== "string") {
        console.log("The magic link token is not a string");
        return;
      }

      await getAuthTokenFromMagicLinkPost({
        tokenCreateRequest: { magic_link_token: url.queryParams.magic_link_token },
      });
    } else if (hasCheckoutQueryParam) {
      const checkoutQueryParamValue = url.queryParams[CHECKOUT_QUERY_PARAM_NAME];

      if (Array.isArray(checkoutQueryParamValue)) {
        Sentry.captureException(new Error("The checkout query param is an array"));
        return;
      }
      if (typeof checkoutQueryParamValue !== "string") {
        Sentry.captureException(new Error("The checkout query param is not a string"));
        return;
      }
      // Check checkoutQueryParamValue is a valid CheckoutState
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      if (checkoutQueryParamValue !== CheckoutParamStates.CHECKOUT) {
        Sentry.captureException(new Error("The checkout query param is not a valid CheckoutParamStates"));
        return;
      }

      setCheckoutState(checkoutQueryParamValue);
    } else {
      console.log("No magic link token or checkout param found in url");
    }
  }

  // NOTE: The magic link logic has been broken up into many functions to facilitate error logging in the compiled app.
  async function handleRedirectUrl(url: string | null): Promise<void> {
    if (!url) {
      return;
    }

    let urlData: Linking.ParsedURL | null = null;
    try {
      urlData = Linking.parse(url);
    } catch (e) {
      logger.error("Error parsing url:", e);
      // NOTE: This is for debugging in the compiled app
      // alert(`Error parsing url: ${e.url}`);
      // alert(`Error with event: ${JSON.stringify(url, null, 2)}`);
    }

    if (urlData) {
      await handleUrlParams(urlData);
    }
  }

  function handleRedirect(event: EventType): Promise<void> {
    logger.debug("Inside handleRedirect with event:", event);

    if (!event.url) {
      logger.error("No url on event:", event);
      // NOTE: This is for debugging the compiled app
      // alert(`No url on event: ${event}`);
      // alert(`Event: ${JSON.stringify(event, null, 2)}`);

      return Promise.resolve(undefined);
    }

    return handleRedirectUrl(event.url);
  }

  const userLoggedIn = isUserLoggedIn(useSelector(userSelector));
  const authToken = useSelector(authTokenSelector);
  const hasUserDeferredPwaInstallPrompt = useSelector(hasUserDeferredPwaPrompt);

  const {
    isLoading: isLoadingUserQuery,
    error: userError,
    data: user,
    // FIXME: When this fires it causes a complete re-render of all components
    // This breaks any flow that is currently in operation (e.g create recipe, food search) as it resets the
    // For now we set the timeout to a high value so updates are eventually done but don't commonly happen.
    // states of components to their initial values.
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useUsersAuthUsersMeRetrieveQuery(undefined, { skip: !authToken, pollingInterval: 30 * 60 * 1000 });

  const is401Error = userError && "status" in userError && userError.status === 401;
  if (is401Error) {
    logger.debug("Error retrieving current user: ", userError);
    logout(dispatch);
  }

  // TODO: We are not cleaning (some of) up these properly and it leads to re-rendering issues
  useEffect(() => {
    // Listener for when app is already open
    Linking.getInitialURL()
      .then(handleRedirectUrl)
      .catch((err) => {
        logger.error(err);
        // NOTE: This is for debugging the compiled app
        // alert("Error after Linking.getInitialURL()");
        // alert(error);
        // alert(JSON.stringify(error, null, 2));
      });

    // Listener for when app is not open
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    eventListenerAddedResultRef.current = Linking.addEventListener("url", handleRedirect);

    dispatch(userSlice.actions.setViewAsUser(null));
  }, [dispatch]);

  const appLastOpenedDateString = useSelector(getAppLastOpenedSelector);

  // On first load, set the date last opened to now
  useEffect(() => {
    const hasAppBeenOpenedRecently = moment().diff(moment(appLastOpenedDateString), "minutes") < 15;
    if (appLastOpenedDateString && !hasAppBeenOpenedRecently) {
      dispatch(plannerSlice.actions.setCurrentDayInPlanner(moment().format()));
    }
  }, [appLastOpenedDateString, dispatch]);

  useEffect(() => {
    if (authTokenResponse) {
      dispatchAuthTokenToStoreIfPresent(authTokenResponse);
    }
  }, [authTokenResponse]);

  useEffect(() => {
    // NOTE: We also check is authToken is present to avoid re-populating the state on a logout call
    if (user && authToken) {
      if (user.id) {
        const userId = String(user.id);
        identifyUserInSegment(user);
        // NOTE: This is for the native library (which is not a snippet and so does not require any waiting to load)
        try {
          identifyUserInIntercom(userId);
        } catch (e) {
          Sentry.captureException(new Error("Error identifying user in Intercom"));
        }

        // This is a backup in case analytics is not loaded when the above is called
        setTimeout(() => {
          identifyUserInSegment(user);
          sendUpdateRequestToIntercom();
        }, TIMEOUT_FOR_ANALYTICS_TO_LOAD_IN_MS);

        // NOTE: Intercom does not always identify correctly so we force an update
        setTimeout(() => {
          sendUpdateRequestToIntercom();
        }, TIMEOUT_FOR_ANALYTICS_TO_LOAD_IN_MS * 2);

        Sentry.setUser({ id: userId, username: user.email });
      } else {
        Sentry.captureException(new Error("User id is not present"));
      }

      dispatch(userSlice.actions.storeUser(user));
    }
  }, [user, authToken]);

  // NOTE: This was tried but caused a blank screen on load
  const LoginGroup = (): JSX.Element => (
    <Stack.Group screenOptions={defaultScreenOptions}>
      <Stack.Screen name={Routes.LoginScreen} component={LoginScreen} />
      <Stack.Screen name={Routes.MagicLinkSentScreen} component={MagicLinkSentScreen} />
      <Stack.Screen name={Routes.EnterOTPScreen} component={EnterOTPScreen} />
    </Stack.Group>
  );

  const LoginStack = createNativeStackNavigator();
  const LoginNestedStack = (): JSX.Element => (
    <LoginStack.Navigator initialRouteName={Routes.LoginScreen} screenOptions={{ headerShown: false }}>
      <Stack.Screen name={Routes.LoginScreen} component={LoginScreen} />
      <Stack.Screen name={Routes.MagicLinkSentScreen} component={MagicLinkSentScreen} />
      <Stack.Screen name={Routes.EnterOTPScreen} component={EnterOTPScreen} />
    </LoginStack.Navigator>
  );

  const AddProductNestedStack = (): JSX.Element => (
    <AddProductStack.Navigator initialRouteName={Routes.AddIngredientScreen} screenOptions={defaultScreenOptions}>
      <AddProductStack.Screen name={Routes.AddIngredientScreen} component={AddIngredientScreen} />
      <AddProductStack.Screen name={Routes.FoodSearchScreen} component={IngredientSearchScreen} />
    </AddProductStack.Navigator>
  );

  const AddRecipeNestedStack = (): JSX.Element => (
    // NOTE: This library component has a ts compile error which we cannot fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AddRecipeTemplateStack.Navigator initialRouteName={Routes.RecipeSearchScreen} screenOptions={defaultScreenOptions}>
      <AddRecipeTemplateStack.Screen name={Routes.RecipeSearchScreen} component={RecipeSearchScreen} />
      <AddRecipeTemplateStack.Screen name={Routes.RecipeDetailScreen} component={RecipeDetailScreen} />
    </AddRecipeTemplateStack.Navigator>
  );

  const CreateOwnRecipeTemplateNestedStack = (): JSX.Element => (
    // NOTE: This library component has a ts compile error which we cannot fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CreateOwnRecipeTemplateStack.Navigator
      initialRouteName={Routes.RecipeAddOwnScreen}
      screenOptions={defaultScreenOptions}
    >
      <AddRecipeTemplateStack.Screen name={Routes.RecipeAddOwnScreen} component={RecipeAddOwnScreen} />
      <AddRecipeTemplateStack.Screen name={Routes.FoodSearchScreen} component={IngredientSearchScreen} />
      <AddRecipeTemplateStack.Screen name={Routes.AddIngredientScreen} component={AddIngredientScreen} />
    </CreateOwnRecipeTemplateStack.Navigator>
  );

  const AddSingleProductByBarcodeNestedStack = (): JSX.Element => (
    // NOTE: This library component has a ts compile error which we cannot fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AddProductByBarcodeStack.Navigator
      initialRouteName={Routes.BarcodeScannerScreen}
      screenOptions={defaultScreenOptions}
    >
      <AddProductByBarcodeStack.Screen name={Routes.BarcodeScannerScreen} component={BarcodeScannerScreen} />
      <AddProductByBarcodeStack.Screen name={Routes.AddIngredientScreen} component={AddIngredientScreen} />
    </AddProductByBarcodeStack.Navigator>
  );

  const EditNutritionPlanNestedStack = (): JSX.Element => (
    // NOTE: This library component has a ts compile error which we cannot fix
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <EditNutritionPlanStack.Navigator screenOptions={defaultScreenOptions}>
      <EditNutritionPlanStack.Screen name={Routes.EnergyBalanceTab1} component={EnergyBalanceTab1} />
      <EditNutritionPlanStack.Screen name={Routes.ChooseMealMomentsTab2} component={ChooseMealMomentsTab2} />
      <EditNutritionPlanStack.Screen name={Routes.EditMealMomentsTab3} component={EditMealMomentsTab3} />
      <EditNutritionPlanStack.Screen name={Routes.FineTuningTab4} component={FineTuningTab4} />
    </EditNutritionPlanStack.Navigator>
  );

  const prefix = Linking.createURL("/");

  const linking = {
    prefixes: [prefix],
    // Custom function to get the URL which was used to open the app
    // async getInitialURL() {
    //   // First, you would need to get the initial URL from your third-party integration
    //   // The exact usage depend on the third-party SDK you use
    //   // For example, to get to get the initial URL for Firebase Dynamic Links:
    //   const { isAvailable } = utils().playServicesAvailability;

    //   if (isAvailable) {
    //     const initialLink = await dynamicLinks().getInitialLink();

    //     if (initialLink) {
    //       return initialLink.url;
    //     }
    //   }

    //   // As a fallback, you may want to do the default deep link handling
    //   const url = await Linking.getInitialURL();

    //   return url;
    // },

    // Custom function to subscribe to incoming links
    // subscribe(listener) {
    //   // Listen to incoming links from Firebase Dynamic Links
    //   const unsubscribeFirebase = dynamicLinks().onLink(({ url }) => {
    //     listener(url);
    //   });

    //   // Listen to incoming links from deep linking
    //   const linkingSubscription = Linking.addEventListener("url", ({ url }) => {
    //     listener(url);
    //   });

    //   return () => {
    //     // Clean up the event listeners
    //     unsubscribeFirebase();
    //     linkingSubscription.remove();
    //   };
    // },

    // config: {
    //   // Deep link configuration
    // },
  };

  function getCheckoutFlowScreen(): JSX.Element {
    switch (checkoutState) {
      case CheckoutParamStates.CHECKOUT:
        return (
          <Stack.Screen
            name={Routes.CheckoutScreen}
            component={CheckoutScreen}
            initialParams={{ [CHECKOUT_PRICE_ID_PARAM_NAME]: checkoutPriceId }}
          />
        );
      default:
        console.error("Unknown checkout state");
        Sentry.captureException(new Error(`Unknown checkout state: ${checkoutState}`));

        return <Stack.Screen name={Routes.LoginStack} component={LoginNestedStack} />;
    }
  }

  const initialScreenIfNotLoggedIn = inCheckoutFlow ? (
    getCheckoutFlowScreen()
  ) : (
    <Stack.Screen name={Routes.LoginStack} component={LoginNestedStack} />
  );

  const pwaSubdomainSignupScreen = <Stack.Screen name={Routes.PWASignupScreen} component={PWASignupScreen} />;

  /**
   * If the organisation is a PWA-based organisation (ie, organisation.uses_pwa = true), then we
   * want the user to be encouraged to install the PWA. We do this by putting up a (dismissable) message on installing.
   *
   * If the user is on desktop then we don't bother showing this option (there will be so few desktop users and
   * they are much less likely to get caught in their email app's web browser (on mobile)).
   *
   * If we are testing the PWA functionality then we want to have an override based on ENV vars.
   *
   * @returns
   */
  function getShouldShowPWAInstallInfoScreen(): boolean {
    if (hasUserDeferredPwaInstallPrompt) {
      return false;
    }

    if (isDesktopScreen()) {
      return false;
    }

    // TODO: This is not currently working
    // if ("Cypress" in window && window.Cypress?.TESTING_PWA === "true") {
    //   return true;
    // }

    const isOnPWASubdomain = brand?.uses_pwa;
    if (isOnPWASubdomain && !platform.pwa) {
      return true;
    }

    return false;
  }

  const getInitialScreen = (): JSX.Element => {
    if (userLoggedIn) {
      // If user is DIY and has not completed the onboarding flow, show the onboarding flow
      return <Stack.Screen name={Routes.BottomNavigationTab} component={BottomNavigationStack} />;
    }

    if (getShouldShowPWAInstallInfoScreen()) {
      return pwaSubdomainSignupScreen;
    }

    return initialScreenIfNotLoggedIn;
  };

  const getCurrentRouteName = (): string | null => {
    if (!navigationRef.current) {
      return null;
    }

    const currentRoute = navigationRef.current?.getCurrentRoute();
    if (!currentRoute) {
      return null;
    }

    return currentRoute.name;
  };

  const navigationContainerOnReady = (): void => {
    const currentRouteName = getCurrentRouteName();
    if (currentRouteName) {
      routeNameRef.current = currentRouteName;
    }
  };
  const navigationContainerOnStateChange = (): void => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = getCurrentRouteName();

    if (currentRouteName !== null && previousRouteName !== currentRouteName) {
      // Save the current route name for later comparison
      routeNameRef.current = currentRouteName;

      analyticsInterface.trackPageOrScreenView(currentRouteName);
    }
  };

  const navigator = (
    <NavigationContainer
      fallback={<Text>Loading...</Text>}
      // See https://reactnavigation.org/docs/screen-tracking/#example
      ref={navigationRef}
      onReady={navigationContainerOnReady}
      onStateChange={navigationContainerOnStateChange}
    >
      <Stack.Navigator screenOptions={parentStackNavigatorScreenOptions}>
        {getInitialScreen()}

        <Stack.Screen name={Routes.SignUpScreen} component={SignUpScreen} />
        <Stack.Screen name={Routes.IntoleranceScreen} component={IntoleranceScreen} />
        <Stack.Screen name={Routes.MealTimesScreen} component={MealTimesScreen} />
        <Stack.Screen name={Routes.DietSelectionScreen} component={DietSelectionScreen} />
        <Stack.Screen name={Routes.WeeklyGoalScreen} component={WeeklyGoalScreen} />
        <Stack.Screen name={Routes.PhysicalStatsScreen} component={PhysicalStatsScreen} />
        <Stack.Screen name={Routes.NutritionalPlanScreen} component={NutritionalPlanScreen} />
        <Stack.Screen name={Routes.ModifyNutritionalPlanScreen} component={ModifyNutritionalPlanScreen} />
        <Stack.Screen name={Routes.BiometricDataScreen} component={BiometricDataScreen} />
        <Stack.Screen name={Routes.ActivityLevelScreen} component={ActivityLevelScreen} />
        <Stack.Screen name={Routes.DIY1GoalScreen} component={DIY1GoalScreen} />
        <Stack.Screen name={Routes.Onboarding_RegistrationScreen} component={RegistrationScreen} />

        <Stack.Screen name={Routes.IngredientDislikesScreen} component={IngredientDislikesScreen} />
        <Stack.Screen name={Routes.PostOnboardingScreen} component={PostOnboardingScreen} />

        {/* NOTE: This is the same component but with a different name (to work with our conditional logic above) */}
        <Stack.Screen name={Routes.LoginScreenOnCheckout} component={LoginScreen} />
        <Stack.Screen name={Routes.GroceryListScreen} component={GroceryListScreen} />
        <Stack.Screen name={Routes.FinishPlanningScreen} component={FinishPlanningScreen} />
        <Stack.Screen name={Routes.DiaryViewScreen} component={DiaryViewScreen} />
        <Stack.Screen name={Routes.OnboardingScreen} component={OnboardingScreen} />
        <Stack.Screen name={Routes.RegisterScreen} component={RegisterScreen} />
        <Stack.Screen name={Routes.EmailConfirmationSent} component={EmailConfirmationSent} />
        <Stack.Screen name={Routes.EmailConfirmed} component={EmailConfirmed} />
        <Stack.Screen name={Routes.GetStaredScreen} component={GetStartedScreen} />
        <Stack.Screen name={Routes.PlanScreen} component={PlanScreen} />
        <Stack.Screen name={Routes.NutritionPlanScreen} component={NutritionPlanScreen} />
        <Stack.Screen name={Routes.PersonalDetailsScreen} component={PersonalDetailsScreen} />
        <Stack.Screen name={Routes.AddGenderScreen} component={AddGenderScreen} />
        <Stack.Screen name={Routes.AddMainGoalScreen} component={AddMainGoalScreen} />
        <Stack.Screen name={Routes.AddMotivationGoalScreen} component={AddMotivationGoalScreen} />
        <Stack.Screen name={Routes.AddLifeStyleScreen} component={AddLifeStyleScreen} />
        <Stack.Screen name={Routes.RecipeSearchScreen} component={RecipeSearchScreen} />
        <Stack.Screen name={Routes.RecipeInspirationScreen} component={RecipeInspirationScreen} />
        <Stack.Screen name={Routes.FoodSearchScreen} component={IngredientSearchScreen} />
        <Stack.Screen name={Routes.BarcodeScannerScreen} component={BarcodeScannerScreen} />
        <Stack.Screen name={Routes.AddProductStack} component={AddProductNestedStack} options={defaultScreenOptions} />
        <Stack.Screen
          name={Routes.AddProductByBarcodeStack}
          component={AddSingleProductByBarcodeNestedStack}
          options={defaultScreenOptions}
        />
        <Stack.Screen
          name={Routes.AddRecipeTemplateStack}
          component={AddRecipeNestedStack}
          options={defaultScreenOptions}
        />
        <Stack.Screen
          name={Routes.RecipeAddOwnStack}
          component={CreateOwnRecipeTemplateNestedStack}
          options={defaultScreenOptions}
        />

        {/* Coach screens */}
        <Stack.Screen name={Routes.CoachModeViewClientsScreen} component={CoachModeViewClientsScreen} />
        <Stack.Screen name={Routes.CoachModeClientInfoScreen} component={CoachModeClientInfoScreen} />
        <Stack.Screen name={Routes.EditUserProfileScreen} component={EditUserProfileScreen} />
        <Stack.Screen name={Routes.EditNutritionDayPlanStack} component={EditNutritionPlanNestedStack} />
        <Stack.Screen name={Routes.CheckoutFailureScreen} component={CheckoutFailureScreen} />
        {/* NutritionDayPlan screens */}
        {/* NOTE: Not currently used */}
        {/* <Stack.Screen name={Routes.FinishPlanningScreen} component={FinishPlanningScreen} /> */}
        {/* <Stack.Screen name={Routes.ProfileStartScreen} component={ProfileStartScreen} /> */}
        {/* <Stack.Screen name={Routes.ProfilePictureScreen} component={ProfilePictureScreen} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );

  return navigator;
}

export default connectActionSheet(NavigationStackComponent);
