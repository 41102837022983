import { Formik } from "formik";
import { Button, FormControl, Input, Select, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { formatMealType } from "../helpers/generalHelpers";
import type { MealSlotSpecification } from "../services/backendTypes";
import styles from "./QuickAddModalStyles";

const QuickAddModal = ({
  mealSlotSpecifications,
  createQuickAddMeal,
}: {
  mealSlotSpecifications: MealSlotSpecification[];
  createQuickAddMeal: ({
    mealSlotSpecificationId,
    calories,
    protein,
    fat,
    carbohydrates,
  }: {
    mealSlotSpecificationId: number;
    calories: number;
    protein: number;
    fat: number;
    carbohydrates: number;
  }) => Promise<void>;
}): JSX.Element => {
  const { t } = useTranslation();
  // TODO: We should improve the logic for this default at a later date
  const defaultSelectedMealSlotSpecificationId = mealSlotSpecifications?.[0]?.id || 0;

  const quickAddSchema = Yup.object().shape({
    // NOTE: Description is not in scope for the initial release
    // description: Yup.string().required("Required"),
    mealSlotSpecificationId: Yup.number().positive().integer().required("Required"),
    calories: Yup.number().positive().required("Required"),
    protein: Yup.number().positive(),
    fat: Yup.number().positive(),
    carbohydrates: Yup.number().positive(),
  });

  interface QuickAddFormValues {
    mealSlotSpecificationId: number;
    calories: string;
    protein: string;
    fat: string;
    carbohydrates: string;
  }

  const onSubmitWrapper = async ({
    mealSlotSpecificationId,
    calories,
    protein,
    fat,
    carbohydrates,
  }: QuickAddFormValues): Promise<void> =>
    createQuickAddMeal({
      mealSlotSpecificationId,
      calories: Number(calories),
      protein: Number(protein),
      fat: Number(fat),
      carbohydrates: Number(carbohydrates),
    });

  return (
    <View>
      <Formik
        initialValues={{
          // description: "",
          mealSlotSpecificationId: defaultSelectedMealSlotSpecificationId,
          calories: "",
          protein: "",
          fat: "",
          carbohydrates: "",
        }}
        validationSchema={quickAddSchema}
        // TODO: Use QuickAddSchema to create the signature for onSubmit
        onSubmit={onSubmitWrapper}
      >
        {({ isSubmitting, handleChange, handleBlur, handleSubmit, values, setFieldValue, errors, dirty, isValid }) => (
          <>
            {/* NOTE: Description is not in scope for the initial release */}
            {/* <FormControl isRequired isInvalid={"description" in errors}>
              <FormControl.Label>{t("Description")}</FormControl.Label>
              <Input
                onBlur={handleBlur("description")}
                textBoxContainerExternalStyle={styles.textBoxContainerStyle}
                externalTextStyle={styles.descriptionStyle}
                placeholder={t("e.g. Main Course at restaurant")}
                onChangeText={handleChange("description")}
                value={String(values.description)}
              />
              <FormControl.ErrorMessage>{errors.description}</FormControl.ErrorMessage>
            </FormControl> */}
            <FormControl isRequired isInvalid={"mealSlotSpecificationId" in errors}>
              <Select
                selectedValue={String(values.mealSlotSpecificationId || defaultSelectedMealSlotSpecificationId)}
                minWidth="200"
                placeholder={t("planner.item.choose_meal_button_text")}
                mt={1}
                onValueChange={(itemValue) => setFieldValue("mealSlotSpecificationId", itemValue)}
              >
                {mealSlotSpecifications?.map((mealSlotSpecification) => (
                  <Select.Item
                    key={mealSlotSpecification.id}
                    label={formatMealType(mealSlotSpecification.meal_type, t)}
                    value={String(mealSlotSpecification.id)}
                  />
                ))}
              </Select>
              <FormControl.ErrorMessage>{errors.mealSlotSpecificationId}</FormControl.ErrorMessage>
            </FormControl>
            <FormControl mt={3} isRequired isInvalid={"calories" in errors}>
              <FormControl.Label>{t("planner.quick_add_modal.input_form_title")}</FormControl.Label>
              <Input
                onBlur={handleBlur("calories")}
                placeholder={t("general.calories")}
                onChangeText={handleChange("calories")}
                value={String(values.calories)}
                testID="quickMealCalories-input"
              />
              <FormControl.ErrorMessage>{errors.calories}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={"protein" in errors}>
              <Input
                onBlur={handleBlur("protein")}
                mt={4}
                placeholder={`${t("general.protein")} ${t("general.in_grams")}`}
                onChangeText={handleChange("protein")}
                value={String(values.protein)}
              />
              <FormControl.ErrorMessage>{errors.protein}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={"fat" in errors}>
              <Input
                onBlur={handleBlur("fat")}
                mt={2}
                placeholder={`${t("general.fat")} ${t("general.in_grams")}`}
                onChangeText={handleChange("fat")}
                value={String(values.fat)}
              />
              <FormControl.ErrorMessage>{errors.fat}</FormControl.ErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={"carbohydrates" in errors}>
              <Input
                onBlur={handleBlur("carbohydrates")}
                mt={2}
                placeholder={`${t("general.carbohydrates")} ${t("general.in_grams")}`}
                onChangeText={handleChange("carbohydrates")}
                value={String(values.carbohydrates)}
              />
              <FormControl.ErrorMessage>{errors.carbohydrates}</FormControl.ErrorMessage>
            </FormControl>

            <Button
              mt={3}
              isLoading={isSubmitting}
              isDisabled={!dirty || !isValid}
              onPress={() => handleSubmit()}
              testID="quickMealSubmit"
            >
              {t("general.add")}
            </Button>
          </>
        )}
      </Formik>
    </View>
  );
};

export default QuickAddModal;
