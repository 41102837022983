// NOTE: Adding @sentry/react to the dependencies causes an error because of dependencies it installs.
// Our other sentry dependencies (@sentry/react-native) already install @sentry/react so we can ignore this error.
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import Constants, { AppOwnership, ExecutionEnvironment } from "expo-constants";
import * as Device from "expo-device";
import * as Localization from "expo-localization";
import { Locale } from "locale-enum";
import _ from "lodash";
import type { Moment } from "moment";
import moment from "moment";
import numeral from "numeral";
import platform from "platform-detect";
import type { TFunction } from "react-i18next";
import { Linking, Platform } from "react-native";

import appJson from "../../../app.json";
import { DEFAULT_LANGUAGE, Images, IS_MOBILE_PLATFORM, isDesktopScreen } from "../constants";
import type { GeneratedRecipeMeal, MealMomentEnum, RecipeMeal, SlimClient, User } from "../services/backendTypes";

export const formatMomentAsBackendFormatDateString = (date: Moment): string => date.format("YYYY-MM-DD");
export const getMomentFromBackendFormatDateString = (dateString: string): Moment => moment(dateString, "YYYY-MM-DD");

/**
 * Format: "Wed Aug 14"
 * @param date
 * @returns
 */
export function formatDate(date: Moment): string {
  // TODO: use locale
  return date.format("ddd MMM D");
}

export const isMobilePlatform = (): boolean => IS_MOBILE_PLATFORM;

export const isIosWeb = (): boolean => platform.ios;
export const isMacOriOSWeb = (): boolean => Platform.OS === "web" && (platform.ios || platform.macos);

export const formatMealType = (mealType: MealMomentEnum, t: TFunction<"translation", undefined>): string =>
  _.capitalize(t(`general.meal_types.${mealType}`));

export const getRecipeMealImage = (
  recipeMeal: RecipeMeal | GeneratedRecipeMeal
  // React native has a terminal error if the uri field of the source of an image is not a string
  // This error occurs on android for our default image because of the way the image is loaded (i.e using a require)
  // This code prevents that but the return type any is not ideal
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { source: { uri: string }; usedDefaultImage: boolean } => {
  let source = Images.DefaultRecipeImage;
  let usedDefaultImage = true;

  if (recipeMeal.recipe_template.image) {
    source = { uri: recipeMeal.recipe_template.image };
    usedDefaultImage = false;
  }

  return {
    source,
    usedDefaultImage,
  };
};

export const formatNumberAsWholeNumber = (number: number): string => numeral(number).format("0");

/**
 * Always round up.
 * @param number
 * @returns
 */
export const formatNumberAsWholeNumberForGroceryList = (number: number): string => String(Math.ceil(number));

export const formatNumberToDecimalPlaces = (number: number, decimalPlaces = 1): string =>
  (Math.round(number * 100) / 100).toFixed(decimalPlaces);

export const formatNumberAsDecimal = (number: number, decimalPlaces = 1): string =>
  String(_.round(number, decimalPlaces));

/**
 *
 * @param number (Assumes 0 = 0% and 1 = 100%)
 * @returns
 */
export const formatNumberAsPercentage = (number: number): string => numeral(number).format("0%");

export const isIos = (): boolean => Platform.OS === "ios";

export const isExpoGo = Constants.appOwnership === AppOwnership.Expo;

export const shouldStartIntercomLauncherHidden = (): boolean => isMobilePlatform() || !isDesktopScreen();

export const IS_ANDROID_WEB = Device.osName === "Android" && Platform.OS === "web";
export const IS_IOS_WEB = Device.osName === "iOS" && Platform.OS === "web";
export const IS_MOBILE_WEB = IS_IOS_WEB || IS_ANDROID_WEB;
export const IS_EXPO_GO = Constants.executionEnvironment === ExecutionEnvironment.StoreClient;

export const formatUserForDisplay = (user: User | SlimClient): string =>
  user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.email;

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
export function apiCallErrorHandler(): ((reason: any) => void | PromiseLike<void>) | null | undefined {
  return (error: string | { status: number; data: { string: string[] } }) => {
    if (typeof error === "string") {
      throw new Error(error);
    } else if ("data" in error) {
      Object.entries(error.data).forEach(([key, value]) => {
        value.forEach((errorMessage) => {
          const errorDescription = `${key}: ${errorMessage}`;
          alert(errorDescription);

          // TODO: This is also not working. This will cause a crash if it executes.
          // toast.show({
          //   description: errorDescription,
          //   placement: "top",
          //   bgColor: "red.400",
          // });
        });
      });
    } else {
      throw new Error(error);
    }
  };
}

/*
 * Form helpers
 */

// TODO: This needs to be changed to work properly with Yup
export const transformEuropeanStyleNumber = (currentValue: string, value: string): string | number | null => {
  if (!value || typeof value !== "string") return value;

  if (value.includes(".")) {
    return null;
  }
  return +value.replace(/,/, ".");
};

export const openURL = (url: string): void => {
  Linking.canOpenURL(url)
    .then(async (supported) => {
      if (supported) {
        await Linking.openURL(url);
      } else {
        Sentry.captureException(new Error(`Cannot open url: ${url}`));
      }
    })
    .catch((e) => {
      console.log("cannot open url", e);

      Sentry.captureException(e);
    });
};

/**
 * A wrapper function which takes another function and wraps it in a try/catch
 * This is to avoid having to wrap every function in a try/catch
 *
 * @param fn
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tryCatchWrapper<T extends (...args: any[]) => any>(fn: T): T {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((...args: any[]) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return fn(...args);
    } catch (e) {
      // NOTE: This is not an error per se and we may downgrade it to be captureMessage if desired
      Sentry.captureException(e);

      return undefined;
    }
  }) as T;
}

export function getDeviceLanguage(): Locale {
  const { locale } = Localization;

  if (locale.toLowerCase().startsWith("nl")) {
    return Locale.nl;
  }
  return Locale.en;
}

export function getLocalizedDay(dateAsString: string, userLocaleFromStore: Locale | undefined): string {
  const internationalizedDayString = moment(dateAsString)
    // userLocaleFromStore is only set when the user changes their language,
    // so if they have not then it will be the default
    .lang(userLocaleFromStore || getDeviceLanguage() || DEFAULT_LANGUAGE)
    .format("dddd");

  return _.capitalize(internationalizedDayString);
}

export const safeGet = <T>(list: T[], index: number): T | null | undefined =>
  list.length > index ? list[index] : null;

export function getAppName(): string {
  const appName = appJson.expo.name;

  return appName;
}

export function getAppIconPath(): string {
  const appIconPath = appJson.expo.icon;

  return appIconPath;
}

export const getNumberOfDaysUntilNextMonday = (date: Moment): number => {
  const dayOfWeek = date.isoWeekday();
  const daysUntilNextMonday = 7 - dayOfWeek;
  return daysUntilNextMonday;
};

export const isDateInCurrentWeek = (date: Moment): boolean => {
  const startOfWeek = moment().startOf("isoWeek");
  const endOfWeek = moment().endOf("isoWeek");
  return date.isBetween(startOfWeek, endOfWeek, "day", "[]");
};
export function addOpacityToBaseHexColour(hex: string, opacity: number): string {
  const opacityHex = opacity.toString(16);

  return `${hex}${opacityHex}`;
}
