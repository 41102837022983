import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, SafeAreaView, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { Button, Icon, useTheme } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { calculateDailyTotalForMacro } from "../../helpers/coachHelpers";
import { DietPreferences, onboardingDataSelector, onboardingSlice } from "../../slices/onboardingSlice";
import { diyFormSelector, userSelector } from "../../slices/userSlice";
import { type MacrosPreference, MacrosPreferenceEnum } from "../../types";
import { createOnboardingState, updateMacrosInState } from "./onboardingHelpers";

interface NutritionalPlanScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

interface Plan {
  label: string;
  description: string;
  value: MacrosPreference;
  emoji: string;
  testID: string;
}

const NutritionalPlanScreen: React.FC<NutritionalPlanScreenProps> = ({ navigation }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const primaryColor = theme.colors?.primary;
  const selectedOptionBackgroundColor = theme.colors?.searchBg;

  const diyFormValues = useSelector(diyFormSelector);
  const onboardingData = useSelector(onboardingDataSelector);
  const user = useSelector(userSelector);

  const [isThisTheFirstScreenOfTheOnboardingTheUserIsSeeing, setIsThisTheFirstScreenOfTheOnboardingTheUserIsSeeing] =
    React.useState(false);

  useEffect(() => {
    if (!diyFormValues?.mss) {
      if (!user) {
        return;
      }
      if (!user.intake) {
        throw new Error("diyFormValues.mss is not set");
      }

      dispatch(onboardingSlice.actions.setUserProfileData(user.intake));
      updateMacrosInState(dispatch, createOnboardingState(user.intake));

      // NOTE: If this user refreshes the app then this will not trigger again and thus they will see a back button.
      // But it doesn't cause a big problem because they can just select the tab from the bottom to come back.
      setIsThisTheFirstScreenOfTheOnboardingTheUserIsSeeing(true);

      // TODO: Note that this code does not work but gives an idea of how to do it
      // // This allows the user to use the back button to go back to the previous screens
      // const previousScreens = [
      //   Routes.DIY1GoalScreen,
      //   Routes.ActivityLevelScreen,
      //   Routes.BiometricDataScreen,
      //   Routes.PhysicalStatsScreen,
      //   Routes.WeeklyGoalScreen,
      // ];
      // previousScreens.reverse().forEach((screenName) => {
      //   console.log(`Pushing screen: ${screenName}`);
      //   navigation.dispatch(StackActions.push(screenName));
      // });
      // console.log("Pushed screens");
    }
  }, []);

  const validationSchema = Yup.object().shape({
    selectedPlan: Yup.mixed<MacrosPreference>().required(
      t("onboarding.onboarding_7_nutrition_plan_screen.validation_error")
    ),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const plans: Plan[] = [
    {
      label: t("onboarding.onboarding_7_nutrition_plan_screen.balanced"),
      description: t("onboarding.onboarding_7_nutrition_plan_screen.balanced_description"),
      value: MacrosPreferenceEnum.RECOMMENDED,
      emoji: "👍",
      testID: `nutrition-plan-option-${MacrosPreferenceEnum.RECOMMENDED}`,
    },
    {
      label: t("onboarding.onboarding_7_nutrition_plan_screen.keto"),
      description: t("onboarding.onboarding_7_nutrition_plan_screen.keto_description"),
      value: MacrosPreferenceEnum.KETO,
      emoji: "🥑",
      testID: `nutrition-plan-option-${MacrosPreferenceEnum.KETO}`,
    },
    {
      label: t("onboarding.onboarding_7_nutrition_plan_screen.modify"),
      description: t("onboarding.onboarding_7_nutrition_plan_screen.modify_description"),
      value: MacrosPreferenceEnum.CHOOSE_YOUR_OWN,
      emoji: "✏️",
      testID: `nutrition-plan-option-${MacrosPreferenceEnum.CHOOSE_YOUR_OWN}`,
    },
  ];

  if (!diyFormValues?.mss) {
    // NOTE: This is actually an error state if it persists.
    console.log("diyFormValues.mss is not set");

    // We use this loading to show something while the useEffect is working
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" />
        <Text style={styles.loadingText}>{"Loading..."}</Text>
      </View>
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const initialValues: FormSchema = {
    selectedPlan: onboardingData.dietPreferences.dietType,
  };

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        {!isThisTheFirstScreenOfTheOnboardingTheUserIsSeeing && (
          <Icon
            name="arrow-back"
            size={24}
            onPress={() => navigation.goBack()}
            tvParallaxProperties={undefined}
            testID="back-button"
          />
        )}
      </View>
      <Formik
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        initialValues={validationSchema.cast(initialValues)}
        validationSchema={validationSchema}
        onSubmit={(values: FormSchema) => navigation.navigate(Routes.MealTimesScreen)}
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <View style={styles.content}>
            <Icon
              name="robot"
              type="material-community"
              size={64}
              color={primaryColor}
              tvParallaxProperties={undefined}
            />
            <Text style={styles.title}>{t("onboarding.onboarding_7_nutrition_plan_screen.title")}</Text>
            <View style={styles.macroContainer}>
              <View style={styles.macroBox} testID="macro-box-calories">
                <Text style={styles.macroLabel}>{t("general.calories")}</Text>
                <Text style={styles.macroValue}>{calculateDailyTotalForMacro("kcal", diyFormValues.mss)}</Text>
                <Text style={styles.macroEmoji}>🔥</Text>
              </View>
              <View style={styles.macroBox} testID="macro-box-protein">
                <Text style={styles.macroLabel}>{t("general.protein_short")}</Text>
                <Text style={styles.macroValue}>{calculateDailyTotalForMacro("protein", diyFormValues.mss)}</Text>
                <Text style={styles.macroEmoji}>💪</Text>
              </View>
              <View style={styles.macroBox} testID="macro-box-fat">
                <Text style={styles.macroLabel}>{t("general.fat_short")}</Text>
                <Text style={styles.macroValue}>{calculateDailyTotalForMacro("fat", diyFormValues.mss)}</Text>
                <Text style={styles.macroEmoji}>🥑</Text>
              </View>
              <View style={styles.macroBox} testID="macro-box-carbs">
                <Text style={styles.macroLabel}>{t("general.carbohydrates_short")}</Text>
                <Text style={styles.macroValue}>{calculateDailyTotalForMacro("carbohydrates", diyFormValues.mss)}</Text>
                <Text style={styles.macroEmoji}>🍞</Text>
              </View>
            </View>
            {plans.map((plan) => (
              <TouchableOpacity
                key={plan.value}
                style={[
                  styles.option,
                  values.selectedPlan === plan.value && {
                    borderColor: primaryColor,
                    backgroundColor: selectedOptionBackgroundColor,
                  },
                ]}
                onPress={() => {
                  setFieldValue("selectedPlan", plan.value);

                  if (plan.value === "CHOOSE_YOUR_OWN") {
                    navigation.navigate(Routes.ModifyNutritionalPlanScreen);
                  } else {
                    // NOTE: We don't do this for CHOOSE_YOUR_OWN because this is done in the Modify screen
                    // This is important because at this point the user hasn't chosen their macros

                    // Update the state with the new diet preferences
                    const updatedDietPreferences: DietPreferences = {
                      ...onboardingData.dietPreferences,
                      dietType: plan.value,
                    };
                    dispatch(onboardingSlice.actions.setDietPreferences(updatedDietPreferences));

                    // We also need to update the state with the new macros if the user selected a different diet type
                    // Note that we need to update the onboarding data locally because it may not have been
                    // updated since the dispatch above
                    const updatedOnboardingData = {
                      ...onboardingData,
                      dietPreferences: updatedDietPreferences,
                    };
                    updateMacrosInState(dispatch, updatedOnboardingData);
                  }
                }}
                testID={plan.testID}
              >
                <View style={styles.optionTextContainer}>
                  <Text style={[styles.optionLabel, values.selectedPlan === plan.value && { color: primaryColor }]}>
                    {plan.emoji} {plan.label}
                  </Text>
                  <Text style={styles.optionDescription}>{plan.description}</Text>
                </View>
                {values.selectedPlan === plan.value && (
                  <Icon
                    name="check-circle"
                    type="font-awesome-5"
                    size={24}
                    color={primaryColor}
                    tvParallaxProperties={undefined}
                  />
                )}
              </TouchableOpacity>
            ))}
            <Button
              title={t("onboarding.onboarding_7_nutrition_plan_screen.next_button")}
              buttonStyle={[styles.nextButton, { backgroundColor: primaryColor }]}
              onPress={() => handleSubmit()}
              testID="next-button"
            />
          </View>
        )}
      </Formik>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  progressText: {
    marginLeft: 16,
    fontWeight: "bold",
  },
  content: {
    flex: 1,
    alignItems: "center",
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
    textAlign: "center",
  },
  macroContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 16,
    width: "100%",
  },
  macroBox: {
    alignItems: "center",
    flex: 1,
    marginHorizontal: 8,
    padding: 16,
    backgroundColor: "#f9f9f9",
    borderRadius: 8,
  },
  macroLabel: {
    fontSize: 14,
    color: "#777",
  },
  macroValue: {
    fontSize: 18,
    fontWeight: "bold",
    marginVertical: 4,
  },
  macroEmoji: {
    fontSize: 24,
  },
  option: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    marginVertical: 8,
    width: "100%",
    minHeight: 70,
  },
  optionTextContainer: {
    flex: 1,
    marginLeft: 16,
  },
  optionLabel: {
    fontSize: 18,
    fontWeight: "bold",
  },
  optionDescription: {
    fontSize: 14,
    color: "#777",
    width: "80%", // Use a percentage-based width for responsiveness
    flexWrap: "wrap",
  },
  nextButton: {
    paddingVertical: 12,
    paddingHorizontal: 32,
    marginTop: 16,
    borderRadius: 8,
    width: "80%",
    alignSelf: "center",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
  },
});

export default NutritionalPlanScreen;
