import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Center, Heading, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isDesktopScreen, Routes } from "../constants";
import { isMobilePlatform } from "../helpers/generalHelpers";
import { StripeCustomerPortalLink } from "../helpers/supportHelpers";
import type { RootStackParamList } from "../navigation/NavigationStackParams";
import { userSelector } from "../slices/userSlice";
import styles from "./LoginScreenStyles";

type Props = NativeStackScreenProps<RootStackParamList, Routes.BillingIssueScreen>;

const BillingIssueScreen = ({ route, navigation }: Props): JSX.Element => {
  const { t } = useTranslation();
  const isDesktop = isDesktopScreen();

  const user = useSelector(userSelector);
  const isCoach = user?.is_coach;
  const isWeb = !isMobilePlatform();

  return (
    <View style={styles.wrapper}>
      <View style={styles.loginContainer}>
        <View style={{ ...styles.wrapper, justifyContent: "center" }}>
          <Center>
            {isCoach ? (
              <View testID="validAccountWarning-coach">
                <Heading>{t("billing_issue_screen.subscription_error")}</Heading>
                {isWeb ? <StripeCustomerPortalLink isDesktop={isDesktop} /> : null}
              </View>
            ) : (
              <View testID="validAccountWarning-client">
                <Heading>{t("billing_issue_screen.client_subscription_error")}</Heading>
              </View>
            )}
          </Center>
        </View>
      </View>
    </View>
  );
};

export default BillingIssueScreen;
