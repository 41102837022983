import { MaterialIcons } from "@expo/vector-icons";
import { BottomTabNavigationOptions, createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import type { TFunction } from "i18next";
import { Icon, useTheme } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Routes } from "../constants";
import { FontFamily, FontSize } from "../constants/fonts";
import {
  hasUserCompletedTheirDiyNutritionPlanningFormCompletely,
  isUserDiy,
  userAccountIsDisabledOrHasInValidSubscription,
} from "../helpers/userHelpers";
import { CoachModeViewClientsScreen } from "../screens";
import BillingIssueScreen from "../screens/BillingIssueScreen";
import CoachModeAccountScreen from "../screens/CoachModeAccountScreen";
import DiaryViewScreen from "../screens/DiaryViewScreen";
import GroceryListScreen from "../screens/GroceryListScreen";
import MyProfileScreen from "../screens/MyProfileScreen";
import MyProgressScreen from "../screens/MyProgressScreen";
import DIY1GoalScreen from "../screens/New_DIY_form/Onboarding_2_GoalScreen";
import NutritionalPlanScreen from "../screens/New_DIY_form/Onboarding_7_NutritionPlanScreen";
import type { User } from "../services/backendTypes";
import { clientModeSelector, userSelector } from "../slices/userSlice";
import type { BottomNavigationTabOptions } from "./NavigationStackParams";

const DELINQUENT_SUBSCRIPTION_SCREENS = [Routes.BillingIssueScreen];

const CLIENT_VIEW_SCREENS: BottomNavigationTabOptions[] = [
  Routes.MyProgressScreen,
  Routes.DiaryViewScreen,
  Routes.GroceryListScreen,
  Routes.MyProfileScreen,
  Routes.BillingIssueScreen,
];

const COACH_VIEW_SCREENS: BottomNavigationTabOptions[] = [
  Routes.CoachModeViewClientsScreen,
  Routes.CoachModeAccountScreen,
  Routes.MyProfileScreen,
  Routes.BillingIssueScreen,
];

const ICONS_LOOKUP: { [B in BottomNavigationTabOptions]: string } = {
  [Routes.DiaryViewScreen]: "calendar-today",
  [Routes.MyProgressScreen]: "bar-chart",
  [Routes.MyProfileScreen]: "person",
  [Routes.CoachModeAccountScreen]: "person",
  [Routes.GroceryListScreen]: "shopping-basket",

  // Coach-mode
  [Routes.CoachModeViewClientsScreen]: "group",
  [Routes.BillingIssueScreen]: "work",

  // Diy
  [Routes.DIY1GoalScreen]: "restaurant-menu",
  [Routes.NutritionalPlanScreen]: "restaurant-menu",
};

const SELECTED_COLOUR = "primary.600";
const UNSELECTED_COLOUR = "gray.400";

function getTabIcon(screenName: BottomNavigationTabOptions, focused: boolean): JSX.Element {
  return (
    <Icon
      as={MaterialIcons}
      m="2"
      ml="3"
      size="6"
      color={focused ? SELECTED_COLOUR : UNSELECTED_COLOUR}
      name={ICONS_LOOKUP[screenName]}
      nativeID={`bottomTabIcon-${screenName}`}
    />
  );
}

function getTitleForTab(route: BottomNavigationTabOptions, t: TFunction): string {
  switch (route) {
    // Client-mode screens
    case Routes.DiaryViewScreen:
      return t("bottom_tabs.planner_tab_name");
    // NOTE: Not currently implemented
    // case Routes.RecipesScreen:
    //   return t("Recipes");
    case Routes.MyProgressScreen:
      return t("bottom_tabs.progress_tab_name");
    case Routes.GroceryListScreen:
      return t("bottom_tabs.groceries_tab_name");
    case Routes.MyProfileScreen:
      return t("bottom_tabs.my_profile_tab_name");
    case Routes.CoachModeAccountScreen:
      return t("bottom_tabs.account_management_tab_name");
    case Routes.BillingIssueScreen:
      return t("bottom_tabs.billing_issue_tab_name");

    // Coach-mode screens
    case Routes.CoachModeViewClientsScreen:
      return t("bottom_tabs.clients_tab_name");

    case Routes.DIY1GoalScreen:
      return t("Welcome");
    case Routes.NutritionalPlanScreen:
      // NOTE: This is valid for the initial onboarding flow but not subsequently -
      // we may want to change this but there is no state that we can use to detect this at the moment.
      return t("Welcome");

    default:
      throw new Error(`Route "${String(route)}" is not implemented for BottomTabNavigator`);
  }
}

const doNotDisplayTab = { tabBarButton: () => null };

function hasUserCompletedUserProfileButDoesNotHaveWeeklyNutritionPlan(user: User): boolean {
  return Boolean(user.intake && !user.intake.weekly_nutrition_plan);
}

const BottomTab = createBottomTabNavigator();
const BottomNavigationStack = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const clientMode = useSelector(clientModeSelector);
  const user = useSelector(userSelector);
  const invalidAccount = userAccountIsDisabledOrHasInValidSubscription(user);

  if (!user) {
    console.error("user is not set in BottomNavigationStack - this should not happen");
    return <></>;
  }

  const isUserDiyAndInClientMode = isUserDiy(user) && clientMode;

  const shouldHideTab = (screenName: BottomNavigationTabOptions): boolean => {
    if (!clientMode) {
      // Ie, coach mode
      return !COACH_VIEW_SCREENS.includes(screenName);
    }
    if (isUserDiyAndInClientMode && !hasUserCompletedTheirDiyNutritionPlanningFormCompletely(user)) {
      if (hasUserCompletedUserProfileButDoesNotHaveWeeklyNutritionPlan(user)) {
        return screenName !== Routes.NutritionalPlanScreen;
      }
      return screenName !== Routes.DIY1GoalScreen;
    }
    return !CLIENT_VIEW_SCREENS.includes(screenName);
  };

  const getScreenOptions = (screenName: string): BottomTabNavigationOptions => ({
    tabBarIcon: ({ focused }) => getTabIcon(screenName as BottomNavigationTabOptions, focused),
    tabBarActiveTintColor: theme.colors.primary["600"],
    tabBarInactiveTintColor: theme.colors.gray["400"],
    headerShown: false,
    tabBarLabelStyle: {
      fontSize: FontSize.mini,
      fontFamily: FontFamily.medium,
    },
    title: getTitleForTab(screenName as BottomNavigationTabOptions, t),
    tabBarTestID: `bottomTab-${screenName}`,
  });

  let initialRouteName;
  if (invalidAccount) {
    initialRouteName = Routes.BillingIssueScreen;
  } else if (clientMode) {
    if (isUserDiyAndInClientMode) {
      if (hasUserCompletedUserProfileButDoesNotHaveWeeklyNutritionPlan(user)) {
        initialRouteName = Routes.NutritionalPlanScreen;
      } else if (!hasUserCompletedTheirDiyNutritionPlanningFormCompletely(user)) {
        initialRouteName = Routes.DIY1GoalScreen;
      } else {
        initialRouteName = Routes.DiaryViewScreen;
      }
    } else {
      initialRouteName = Routes.DiaryViewScreen;
    }
  } else {
    initialRouteName = Routes.CoachModeViewClientsScreen;
  }

  return (
    // NOTE: This is a known bug due to a react version update: https://stackoverflow.com/questions/71788254/react-18-typescript-children-fc/71809927#71809927
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <BottomTab.Navigator
      screenOptions={({ route: { name: nameAsString } }) => {
        const name = nameAsString as BottomNavigationTabOptions;

        // if help screen then display
        if (name === Routes.MyProfileScreen) {
          return getScreenOptions(name);
        }

        if (invalidAccount && !DELINQUENT_SUBSCRIPTION_SCREENS.includes(name)) {
          return doNotDisplayTab;
        }

        if (shouldHideTab(name) || (!invalidAccount && DELINQUENT_SUBSCRIPTION_SCREENS.includes(name))) {
          return doNotDisplayTab;
        }

        return getScreenOptions(name);
      }}
      initialRouteName={initialRouteName}
    >
      <BottomTab.Screen name={Routes.DiaryViewScreen} component={DiaryViewScreen} />
      {/* DIY screens */}
      <BottomTab.Screen name={Routes.DIY1GoalScreen} component={DIY1GoalScreen} />
      <BottomTab.Screen name={Routes.NutritionalPlanScreen} component={NutritionalPlanScreen} />
      <BottomTab.Screen name={Routes.GroceryListScreen} component={GroceryListScreen} />
      <BottomTab.Screen name={Routes.CoachModeViewClientsScreen} component={CoachModeViewClientsScreen} />
      <BottomTab.Screen name={Routes.CoachModeAccountScreen} component={CoachModeAccountScreen} />
      <BottomTab.Screen name={Routes.BillingIssueScreen} component={BillingIssueScreen} />
      <BottomTab.Screen name={Routes.MyProgressScreen} component={MyProgressScreen} />
      {/* NOTE: This is out of scope for the initial release */}
      {/* <BottomTab.Screen name={Routes.RecipesScreen} component={RecipeInspirationScreen} /> */}
      <BottomTab.Screen name={Routes.MyProfileScreen} component={MyProfileScreen} />
    </BottomTab.Navigator>
  );
};

export default BottomNavigationStack;
