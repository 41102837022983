import type { NavigationProp, ParamListBase } from "@react-navigation/native";
import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { SafeAreaView, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { Button, Icon, useTheme } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Routes } from "../../constants";
import { onboardingDataSelector, setBiometricData } from "../../slices/onboardingSlice";
import { GenderEnum } from "../../types";

interface BiometricDataScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const BiometricDataScreen: React.FC<BiometricDataScreenProps> = ({ navigation }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const onboardingData = useSelector(onboardingDataSelector);

  const validationSchema = Yup.object().shape({
    age: Yup.number().min(18).max(120).required(t("onboarding.onboarding_4_biometric_data_screen.valid_age")),
    gender: Yup.mixed<GenderEnum>()
      .oneOf(Object.values(GenderEnum))
      .required(t("onboarding.onboarding_4_biometric_data_screen.select_gender")),
  });

  type FormSchema = Yup.InferType<typeof validationSchema>;

  const onSubmit = (values: FormSchema): void => {
    dispatch(setBiometricData({ age: values.age, gender: values.gender }));

    navigation.navigate(Routes.PhysicalStatsScreen);
  };

  const selectedOptionBackgroundColor = theme.colors?.searchBg || `${theme.colors?.primary}20`; // 20 is the hex for 12.5% opacity

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      initialValues={validationSchema.cast({
        age: onboardingData.biometricData?.age || 25,
        gender: onboardingData.biometricData?.gender || GenderEnum.FEMALE,
      })}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, setFieldValue, values, errors, touched }) => {
        const isMale = values.gender === GenderEnum.MALE;
        const isFemale = !isMale;

        const handleGenderPress = (gender: GenderEnum): void => {
          setFieldValue("gender", gender);
        };

        return (
          <SafeAreaView style={styles.container}>
            <View style={styles.header}>
              <Icon
                name="arrow-back"
                size={24}
                onPress={() => navigation.goBack()}
                tvParallaxProperties={undefined}
                testID="back-button"
              />
            </View>
            <View style={styles.content}>
              <Icon
                name="robot"
                type="material-community"
                size={64}
                color={theme.colors?.primary}
                tvParallaxProperties={undefined}
              />
              <Text style={styles.title}>{t("onboarding.onboarding_4_biometric_data_screen.title")}</Text>
              <Text style={styles.subtitle}>{t("onboarding.onboarding_4_biometric_data_screen.subtitle")}</Text>

              <Text style={[styles.label, styles.boldLabel]}>
                {t("onboarding.onboarding_4_biometric_data_screen.age_label")}
              </Text>
              <TextInput
                style={[styles.input, styles.ageInput]}
                placeholder={t("onboarding.onboarding_4_biometric_data_screen.age_placeholder")}
                keyboardType="numeric"
                value={String(values.age)}
                onChangeText={(text) => setFieldValue("age", text)}
                selectTextOnFocus
                testID="age-input"
              />
              {errors.age && touched.age ? (
                <Text style={styles.errorText} testID="age-error">
                  {errors.age}
                </Text>
              ) : null}

              <Text style={[styles.label, styles.boldLabel]} testID="gender-label">
                {t("onboarding.onboarding_4_biometric_data_screen.gender_label")}
              </Text>
              <View style={styles.genderContainer}>
                <TouchableOpacity
                  style={[
                    styles.genderOption,
                    isMale && { borderColor: theme.colors?.primary, backgroundColor: selectedOptionBackgroundColor },
                  ]}
                  onPress={() => handleGenderPress(GenderEnum.MALE)}
                  testID={`gender-option-${GenderEnum.MALE}`}
                >
                  <Text style={styles.genderEmoji}>{"👨 "}</Text>
                  <Text style={[styles.genderLabel, isMale && { color: theme.colors?.primary }]}>
                    {t("onboarding.onboarding_4_biometric_data_screen.male")}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    styles.genderOption,
                    isFemale && { borderColor: theme.colors?.primary, backgroundColor: selectedOptionBackgroundColor },
                  ]}
                  onPress={() => handleGenderPress(GenderEnum.FEMALE)}
                  testID={`gender-option-${GenderEnum.FEMALE}`}
                >
                  <Text style={styles.genderEmoji}>{"👩 "}</Text>
                  <Text style={[styles.genderLabel, isFemale && { color: theme.colors?.primary }]}>
                    {t("onboarding.onboarding_4_biometric_data_screen.female")}
                  </Text>
                </TouchableOpacity>
              </View>
              {errors.gender && touched.gender ? (
                <Text style={styles.errorText} testID="gender-error">
                  {errors.gender}
                </Text>
              ) : null}

              <Button
                title={t("onboarding.onboarding_4_biometric_data_screen.next_button")}
                buttonStyle={styles.nextButton}
                onPress={() => handleSubmit()}
                testID="next-button"
              />
            </View>
          </SafeAreaView>
        );
      }}
    </Formik>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
  },
  progressText: {
    marginLeft: 16,
    fontWeight: "bold",
  },
  content: {
    flex: 1,
    alignItems: "center",
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 16,
    color: "#777",
    textAlign: "center",
    marginVertical: 16,
  },
  label: {
    fontSize: 16,
    color: "#333",
    marginVertical: 8,
  },
  boldLabel: {
    fontWeight: "bold",
  },
  input: {
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 8,
    width: "80%",
    padding: 8,
    marginVertical: 8,
    textAlign: "center",
  },
  ageInput: {
    width: "40%",
  },
  genderContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    marginVertical: 16,
  },
  genderOption: {
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#ddd",
    width: "40%",
    justifyContent: "center",
  },
  genderLabel: {
    fontSize: 16,
    marginLeft: 8,
  },
  genderEmoji: {
    fontSize: 24,
  },
  errorText: {
    color: "red",
    marginTop: 8,
    textAlign: "center",
  },
  nextButton: {
    paddingVertical: 12,
    paddingHorizontal: 32,
    marginTop: 16,
    borderRadius: 8,
    width: "80%",
    alignSelf: "center",
  },
});

export default BiometricDataScreen;
